import axios, { Axios } from 'axios';

import { getAuth, onAuthStateChanged } from "firebase/auth";
import { AppProject, ConversationSession, Index, MetaType, SearchResult, TopicInfo } from './models/dataModel';

let api:ApiService = null;
function getApi(): ApiService{
  
    
    
    if (!(api)){ 
        const auth = getAuth();
        onAuthStateChanged(auth, (currentUser) => {
            if (api){
                api.currentUser=currentUser;
            }
        });

        api = new ApiService(process.env.REACT_APP_API_URL || "http://localhost:4080" )//, auth.currentUser);
        
        //api = new ApiService("http://localhost:4000/", auth.currentUser);
    }
    
    return api
}



class ApiService{
    baseUrl: string;
    
    currentUser: any;
    axiosInstance:Axios;
    

    constructor(baseUrl: string) {
      this.baseUrl = baseUrl ;

      
      this.axiosInstance= axios.create();
      
      this.axiosInstance.interceptors.request.use(
        async config =>  {
            if (!(api&&api.currentUser))
                await this.tryWaitForAuth(10)
            if ( api &&(  api.currentUser)){
                const token = await api.currentUser.getIdToken();
                if (token && config &&  config.headers) {
                    config.headers['authorization'] = 'Bearer ' + token;
                }
            }
            config.headers['Content-Type'] = 'application/json';
            
            const tempApiKey = new URLSearchParams(window.location.search).get('temp-api-key');
            if (tempApiKey){
              config.headers['x-api-key'] = tempApiKey;
            }
            
            return config;
        },
        error => {
            
            Promise.reject(error)
        });
        this.axiosInstance.interceptors.response.use(undefined, (error)=>{
            if (error.response && error.response.status===403){
                
                if (!window.location.href.endsWith("/login") && !window.location.href.endsWith("/signup")){
                   setTimeout(()=>{window.location.href="/login?force=true"},500);
                }
                return Promise.reject(error.response);

              } 
            else
                return Promise.reject(error);
        })
    }

    async tryWaitForAuth(countdown:number) {
      if (!this.currentUser && countdown>0){
          await new Promise(r => setTimeout(r, 200)); //Wait 1s for auth
          if (!this.currentUser){
              await this.tryWaitForAuth(countdown-1)
          }
      }
  }

  saveApp(entity:AppProject): Promise<AppProject> {
    return this.axiosInstance.post(this.baseUrl + `/management/apps`, entity)
      .then((response) => {
        return response.data;
      });          
  }

  getApp(appId:string): Promise<AppProject> {
    return this.axiosInstance.get(this.baseUrl + `/management/apps/${appId}`)
      .then((response) => {
        return response.data;
      });          
  }

  getIndex(index_id:string): Promise<Index> {
    return this.axiosInstance.get(this.baseUrl + `/management/indexes/${index_id}`)
      .then((response) => {
        return response.data;
      });          
  }

  queryIndex(index_id:string, query:string, skip:number=0, limit:number=25): Promise<SearchResult[]> {
    return this.axiosInstance.get(this.baseUrl + `/management/indexes/${index_id}/query`, {params:{query, skip, limit}})
      .then((response) => {
        return response.data;
      });          
  }

  reindexDatasource(appId:string, datasource_uuid:string, source_name:string=null): Promise<SearchResult[]> {
    return this.axiosInstance.post(this.baseUrl + `/management/apps/${appId}/datasources/${datasource_uuid}/reindex`, {params:{source_name}})
      .then((response) => {
        return response.data;
      });          
  }

  getAllApps(): Promise<AppProject[]> {
    return this.axiosInstance.get(this.baseUrl + `/management/apps`)
      .then((response) => {
        return response.data;
      });          
  }

  deleteApp(appId:string): Promise<null> {
    return this.axiosInstance.delete(this.baseUrl + `/management/apps/${appId}`)
      .then((response) => {
        return response.data;
      });          
  }

  getMetaSchemaTypes(entity): Promise<MetaType[]> {
    return this.axiosInstance.get(this.baseUrl + `/meta/schema/${entity}`)
      .then((response) => {
        return response.data;
      });          
  }
  getMetaSchema(entity, entityType): Promise<JSONSchema> {
    return this.axiosInstance.get(this.baseUrl + `/meta/schema/${entity}/${entityType}`)
      .then((response) => {
        return response.data;
      });          
  }

 

      
  getSessions( {
        appId,
        q,
        skip=0,
        limit=25,
        dateRangeStart,
        dateRangeEnd,
      }:{
        appId:string,
        q:string,  
        dateRangeStart?:string,
        dateRangeEnd?:string,
        skip:number, 
        limit:number
      }): Promise<ConversationSession[]> {
        return this.axiosInstance.get(this.baseUrl + `/${appId}/conversations/sessions`, {params:{q, skip, limit, dateRangeStart, dateRangeEnd}})
          .then((response) => {
            return response.data;
          });          
      }
    
      getMessages(appId, session_id): Promise<any[]> {
        return this.axiosInstance.get(this.baseUrl + `/${appId}/conversations/sessions/${session_id}/messages`)
          .then((response) => {
            return response.data;
          });          
      }

      getTopics( {
        appId,
        skip=0,
        limit=25
      }:{
        appId:string,
        skip:number, 
        limit:number
      }): Promise<TopicInfo[]> {
        return this.axiosInstance.get(this.baseUrl + `/${appId}/conversations/topics`, {params:{skip, limit}})
          .then((response) => {
            return response.data;
          });          
      }


      getActiveIntegrations(): Promise<{connector:string, settings:any}[]> {
        return this.axiosInstance.get(this.baseUrl + `/integrations`)
          .then((response) => {
            return response.data;
          });          
      }

      connectIntegration( connector:string): Promise<{url:string}> {
        return this.axiosInstance.get(this.baseUrl + `/integrations/${connector}/connect`)
          .then((response) => {
            return response.data;
          });          
      }

      connectIntegrationCallback( connector:string, queryParams): Promise<{success:boolean}> {
        return this.axiosInstance.get(this.baseUrl + `/integrations/${connector}/connect`,{params:queryParams})
          .then((response) => {
            return response.data;
          });          
      }






      patchAccountInfo(accountId,payload:{
        active?:boolean,
        role?:string,
        name?:string,
        email?:string,
        new_password?:string,
      }): Promise<null> {
        return this.axiosInstance.patch(this.baseUrl + `/org/accounts/${accountId}`, payload)
          .then((response) => {
            return response.data;
          });          
      }

      getMyAccount(): Promise<{id:string,name:string,role:string, email:string}> {
        return this.axiosInstance.get(this.baseUrl + "/org/my-account")
          .then((response) => {
            if (response.status<300){
              return response.data;
            }
            throw new Error(response.statusText)
          });          
      }

      getAccounts(): Promise<Account[]> {
        return this.axiosInstance.get(this.baseUrl + "/org/accounts")
          .then((response) => {
            return response.data;
          });          
      }

      passwordReset(email,payload:{
          verification_code:string,
          new_password:string,
        }=undefined): Promise<null> {
        return this.axiosInstance.post(this.baseUrl + "/org/password-reset", payload,{params:{email}})
          .then((response) => {
            return response.data;
          });          
      }

      signup(payload:{
        email:string,
        password?:string,
        account_id?:string,
        invite_id?:string,
      }=undefined): Promise<null> {
      return this.axiosInstance.post(this.baseUrl + "/org/signup", payload)
        .then((response) => {
          return response.data;
        });          
      }

      createInvite(payload:{
        email:string,
        new_tenant?:boolean,
        role:string,
      }=undefined): Promise<null> {
      return this.axiosInstance.post(this.baseUrl + "/org/invites", payload)
        .then((response) => {
          return response.data;
        });          
      }

      testInvite(email:string, verification:{
        invite_id?:string,
        invite_verification_code?:string,
      }): Promise<{
        invite_id:string,
        tenant:string,
        valid_until:string
      }> {
        return this.axiosInstance.get(this.baseUrl + "/org/invites/test", {params:{email, ...verification}})
        .then((response) => {
          return response.data;
        }); 
      }







      getMyOrganizationInfo(): Promise<{
        name:string,
        tenant_id:string,
        plan:string
        active_until:string
      }> {
        return this.axiosInstance.get(this.baseUrl + "/org/info")
        .then((response) => {
          return response.data;
        }); 
      }

      managePlan(plan:string): Promise<null> {
        return this.axiosInstance.patch(this.baseUrl + "/org/plan/manage",{plan})
        .then((response) => {
          return response.data;
        }); 
      }

      checkoutSession(plan:string,
        followup_url:string
      ): Promise<{checkout_session_url}> {
        return this.axiosInstance.post(this.baseUrl + "/org/plan/activate", null,{params:{plan,followup_url}})
        .then((response) => {
          return response.data;
        }); 
      }

      checkoutSessionFinish(checkout_session_id:string
      ): Promise<{}> {
        return this.axiosInstance.post(this.baseUrl + "/org/plan/activate/finish", null,{params:{checkout_session_id}})
        .then((response) => {
          return response.data;
        }); 
      }


      requestSupport(message:string): Promise<{}> {
          return this.axiosInstance.post(this.baseUrl + "/org/manage/support", {message})
          .then((response) => {
            return response.data;
          }); 
        }

      getBillingInfo(
        ): Promise<BillingInfo> {
          return this.axiosInstance.get(this.baseUrl + "/org/manage/billing-info")
          .then((response) => {
            return response.data;
          }); 
        }

        // getBillingCountries(
        //   ): Promise<string[]> {
        //     return this.axiosInstance.get(this.baseUrl + "/org/manage/billing-info/countries")
        //     .then((response) => {
        //       return response.data;
        //     }); 
        //   }
      
      updateBillingInfo(billingInfoData:BillingInfo
          ): Promise<null> {
            return this.axiosInstance.patch(this.baseUrl + "/org/manage/billing-info", billingInfoData)
            .then((response) => {
              return response.data;
            }); 
          }

      getInvoices(
            ): Promise<InvoiceInfo[]> {
              return this.axiosInstance.get(this.baseUrl + "/org/billing/invoices")
              .then((response) => {
                return response.data;
              }); 
            }
          
      getInvoice(invoice_id:string
              ): Promise<{url:string}> {
                return this.axiosInstance.get(this.baseUrl + `/org/billing/invoices/${invoice_id}`)
                .then((response) => {
                  return response.data;
                }); 
              }

      getUpcomingInvoice(
              ): Promise<InvoiceInfo> {
                return this.axiosInstance.get(this.baseUrl + "/org/billing/invoices/upcoming")
                .then((response) => {
                  return response.data;
                }); 
              }

}
    

export {getApi}