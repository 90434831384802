import { chakra, Box, Button, ButtonGroup, CloseButton, Flex, IconButton, Input, InputGroup, InputLeftElement, InputRightElement, Link, Progress, Spinner, Stack, Tag, TagLeftIcon, Text, Tooltip, Accordion, AccordionItem, AccordionButton, Badge, AccordionPanel } from "@chakra-ui/react";

import { getApi } from "../../apiService";
import useSWRInfinite from 'swr/infinite'
import { Icon3dCubeSphere, IconBlockquote, IconCompass, IconSearch } from "@tabler/icons-react";
import Moment from "react-moment";
import { useEffect, useMemo, useState } from "react";
import { SearchResult } from "../../models/dataModel";
import { BiNews } from "react-icons/bi";

import { IconExternalLink } from "@tabler/icons-react";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import { IconChevronUp } from "@tabler/icons-react";
import remarkBreaks from 'remark-breaks'
import remarkGfm from 'remark-gfm'


const IndexSearch= ({index_id}) => {
    function queryIndex(queryString){
        const queryParams = new URLSearchParams(queryString)
        const skip = parseInt(queryParams.get('skip'))
        const limit = parseInt(queryParams.get('limit'))
        const q = queryParams.get('q')
        if (!index_id) return
        return getApi().queryIndex(index_id,q,skip,limit ).then((res)=>{
            return res
        })
    }
    const [isReady, setIsReady] = useState(false)
    useEffect(()=>{
        setIsReady(true)
    },[])

    const [_query,_setQuery] = useState("")
    const [query,setQuery] = useState("")
    const PAGE_SIZE=25
    const { data:pages, size, setSize ,isLoading} = useSWRInfinite(
        (index) =>`isReady=${isReady}&limit=${PAGE_SIZE}&skip=${index *PAGE_SIZE}&q=${query}`,
        (queryString)=>{
            if (isReady) return queryIndex(queryString)
        },{
            revalidateIfStale:true,
            //revalidateFirstPage:false
        }
      );

    //const {data:indexingProgress, isLoading:indexingProgressLoading} = useSWR(`index/`+index_id,()=>searchIndex())
    
    return <Stack position="relative"  >
            <Box position="sticky" top="-10px"  background="white" p="10px 5px "  zIndex={9}>
             <InputGroup >
                <InputLeftElement pointerEvents='none'>
                <IconSearch color='gray' size="15px" />
                </InputLeftElement>
                <Input 
                onKeyDown={(e)=>{
                    if (e.key=="Enter"){
                        setQuery(_query)
                    }
                }}
                placeholder='Search' value={_query} onChange={(e)=>_setQuery(e.target.value)}/>
                <InputRightElement width='6.5rem'>
                <Button m="5px" h="1.5rem" size="xs" colorScheme={query==_query?undefined:"blue"}
                onClick={()=>setQuery(_query)}
                >Apply search</Button>
                </InputRightElement>
            </InputGroup>
            </Box>
            {isLoading ? <Flex width="100%" p="20px" justify="center" ><Spinner size="lg"/></Flex>:(
            <>
                {pages && pages.map((page_items,page_i) => (
                    page_items?.map((result_item, index) => (
                            <SearchResultBox key={100*page_i + index} searchResult={result_item} />
                        ))
                    ))}
            {pages &&pages[pages.length-1] && pages[pages.length-1] && pages[pages.length-1].length==PAGE_SIZE && <Button size="xs" onClick={()=>setSize(size+1)}>Load more</Button>}
            </>)}
            
          
        </Stack>

}



function ThumbnailImage({ src,  fallbackIcon, size = "20px" }:{
    src:string,
    fallbackIcon?:any
    size?:string
}) {
    const [error, setError] = useState(false);
    return src && (!error)?(
      <Box height={size} borderWidth={0.5} borderColor="rgba(120,120,120,0.5)"  backgroundColor="white" borderRadius={5} overflow="hidden">
        <img
          src={src}
          style={{ height: "100%", aspectRatio: "1 / 1", objectFit: "cover" }}
          onError={(e) => (setError(true))}
        />
       </Box>
    ):(
        <Box backgroundColor="white" p="5px" borderRadius={5}>
            {fallbackIcon}
        </Box>
    )
  }

function SearchResultBox({searchResult, onClick}:{
    searchResult:SearchResult
    onClick?:()=>void
}){

    

    function getThumbnail(size:"small"|"medium"|"large"="small"){
        let baseSizePx = 20
        switch(size){
            case "small":
                baseSizePx=20;
                break;
            case "medium":
                baseSizePx=50;
                break;
            case "large":
                baseSizePx=100;
                break;

        }
        let fallbackIcon:any=null;
        if (searchResult.type=="navigation_item"){
            fallbackIcon = <IconCompass size={baseSizePx+"px"}/>
        }
        else if (searchResult.url){
            fallbackIcon = <BiNews size={baseSizePx+"px"}/>
        }
        else{
            fallbackIcon = <IconBlockquote size={baseSizePx+"px"}/>
        }
        return <ThumbnailImage src={searchResult.thumbnail_img} fallbackIcon={fallbackIcon} size={(baseSizePx*2.5)+"px"}/>
           
        
    }

    function formatMetadata(){
        
        const show_metadata=true
        const metadata =  searchResult.metadata;
        if (metadata && show_metadata ){
            let keys=Object.keys(metadata).filter(k=>!k.startsWith("_") && ((metadata as any)[k]))
            if (Array.isArray(show_metadata)){
                keys=show_metadata
            }
            let shortProps = keys.filter(k=> ((metadata as any)[k])?.length<100).map((key)=>(
                <Stack p="2px" key={key} direction="row" width="100%" align="center">
                    <Text fontSize="xs" fontWeight={900} textTransform="capitalize">{key.replace("_"," ")}:</Text>
                    {key}

                    <Flex justify="space-between"   flexGrow={1} align="center">
                    <Badge>{ (metadata as any)[key]}</Badge>

                    
                    </Flex>
                    
                </Stack>
            ))
           

            let longPropsKeys = keys.filter(k=>((metadata as any)[k])?.length>=100)
            let longProps =<></>
            if (longPropsKeys.length>0){
                longProps = (
                    <Box width="100%" overflow="hidden" >
                    <Box fontSize="xs" overflow="hidden"  width="100%">
                        <Accordion width="100%" allowToggle>

                        {longPropsKeys.filter(k=>((metadata as any)[k])?.length>=100).map((key, i)=>(
              
                
                        <AccordionItem key={i} width="100%">
                             {({ isExpanded }) => (
                                <>
                            <AccordionButton width="100%" p="2px">
                                <Text fontWeight={700} fontSize="xs" whiteSpace="nowrap">{key}: </Text>
                                {!isExpanded && <Text maxH="1.5em" width="100%" whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis" fontSize="xs" >  {(metadata as any)[key]}</Text>}
                            </AccordionButton>
                            <AccordionPanel  overflow="auto" maxH="50vh">
                                <Box css={` 
                                        p {
                                            font-size: 12px;
                                            font-family:  "Segoe UI", "Arial", sans-serif;
                                        }
                                        & li {margin-left: 15px;
                                            font-size: 12px;                    
                                            font-family:   "Segoe UI", "Arial", sans-serif;
                                        }
                                        `}>

                                
                                    {(metadata as any)[key]}
                                
                                </Box>
                            </AccordionPanel>
                            </>)}
                        </AccordionItem>
                        ))}

                        </Accordion>
                    </Box>
                </Box>
                )
                    
            }
            
            
            return <Box> 
                
                {shortProps}
                <Box>{longProps}</Box>
            </Box>
        }
        else{
            return <></>
        }
    }


    const primaryMetadata = useMemo(()=>formatMetadata(),[searchResult])
    return (
        <Stack direction="row" align="center"   spacing={2} p="5px" borderRadius={5} borderWidth={0.5}  
            cursor={onClick ?"pointer":undefined}
            onClick={()=>onClick && onClick()}
            
        >
            <Box  
                alignSelf="flex-start" 
                flexGrow={0}
                flexShrink={0}
            >
                {getThumbnail()}
            </Box>
            
            <Stack direction="column" spacing={0} overflow="hidden" >
            {searchResult.url ? <Link fontSize="2xs" href={searchResult.url} target="_blank">
                <Flex>
                
                <chakra.span  wordBreak="break-word" fontWeight={800} fontSize="xs">{searchResult.title}</chakra.span >
                <IconExternalLink size="12px" color="gray"/>
                </Flex>
            </Link>:(

                <Text  wordBreak="break-word" fontWeight={800} fontSize="xs">{searchResult.title}</Text>
            )}
                {searchResult.text && 
                <Box overflow="hidden">
                    <Accordion allowToggle>
                    <AccordionItem width="100%">
                    {({ isExpanded }) => (
                        <>
                        <AccordionButton width="100%" p="2px" textAlign="start">
                        {isExpanded?<><IconChevronUp size="15px"/><Link textColor="blue.500" fontSize="xs">Collapse content</Link></>:(
                            <Text  textOverflow="ellipsis"   maxH="3.5em" wordBreak="break-word" fontWeight={400} fontSize="2xs" _hover={{wordBreak:"unset"}}>{
                                searchResult.text
                            }
                            </Text>
                        )}
                        </AccordionButton>

                        <AccordionPanel  overflow="auto" maxH="50vh">
                        {isExpanded?(
                        <Stack  maxH="40vh" css={`
                        h1, h2, h3, h4, h5, h6 { font-weight: bold; }
                        h1 { font-size: 15px; }
                        h2 { font-size: 14px; }
                        h3 { font-size: 13px; }
                        h4 { font-size: 12px; }
                        h5 { font-size: 11px; }
                        h6 { font-size: 10px; }
                        p {
                            font-size: 10px;
                        }
                        `}>

                        <ReactMarkdown  remarkPlugins={[remarkBreaks, remarkGfm]}>
                            
                            {searchResult.text}
                        </ReactMarkdown>
                        </Stack>

                        ):(
                        <Stack fontSize="2xs" maxH="200px" overflow="auto">

                        </Stack>
                        )}
                       
                        </AccordionPanel>
                        </>
                    )}
                    </AccordionItem>
                    </Accordion>
                </Box>
                }
                <Box>

                
                </Box>
                {primaryMetadata}
            </Stack>
        </Stack>
    )
}


export {IndexSearch}

