

import { useEffect } from 'react';
import './App.css';
import PageRouter from './PageRouter';

import { AppContextProvider } from './appContext';
import { Box, ChakraProvider, extendTheme } from '@chakra-ui/react'



  // // Add this in node_modules/react-dom/index.js
  // (window as any).React1 = require('react');

  // // Add this in your component file
  // require('react-dom');
  // (window as any).React2 = require('react');
  // console.log("react",(window as any).React1 === (window as any).React2);

// 2. Extend the theme with new layer styles
const chakraTheme = extendTheme({
  colors: {
    brand: {
      50: "#ff00c810",
      100: "#ff00c820",
      200: "#ff00c8a0",
      300: "#ff00c8b0",
      400: "#ff00c8c0",
      500: "#ff00c8d0",
      600: "#ff00c8e0",
      700: "#ff00c8f0",
      800: "#ff00c8",
      900: "#ff00c8",
    },
  },
})


function App() {

  
  return (
    <Box className="App"  height="100vh" width="100vw" overflow="hidden">
       {/* <Grommet theme={theme}> */}
       <ChakraProvider theme={chakraTheme}>
       
        <AppContextProvider>
        
          <PageRouter/>
        
        </AppContextProvider>
       </ChakraProvider>
       {/* </Grommet> */}
    </Box>
  );
}

export default App;
