

import { useEffect, useMemo, useState } from "react";
import { Box, CloseButton, Flex, IconButton, Input, Select, Spinner, Switch, TagCloseButton, Text, Tooltip } from '@chakra-ui/react'
import "./AutoUI.css"
import { AutosizeTextInput } from "../AutosizeTextInput/autosizeTextInput";
import { AutosizeTextArea } from "./AutosizeTextArea";
import { FiEdit } from "react-icons/fi";
import { CgRemoveR } from "react-icons/cg";
import { HiMinus } from "react-icons/hi2";
import { IoMdClose } from "react-icons/io";
import { error } from "console";
import { BiMessageSquareError } from "react-icons/bi";




export const AutoUI = ({ value, schema, excludeFields, size="sm", type = "object", validationErrors, onValueChange,parentPath="", readOnly,customEditors }:{
    value?:any,
    schema?:JSONSchema,
    excludeFields?:string[],
    size?:string,
    type?:JSONSchemaType| null| undefined | JSONSchemaType[],
    parentPath?:string,
    validationErrors?:{loc:string[], msg:string}[],
    readOnly?:boolean,
    onValueChange?:(value:any)=>void
    customEditors?:{[key:string]:any},
}) => {

    const [valueState, setValueState] = useState({});

    function getChildErrors(field){
        return validationErrors?.filter(e=>e.loc[0]==field).map(e=>({
            ...e,
            loc:e.loc.slice(1)
        }))
    }
    function getFieldErrors(field){
        return validationErrors?.filter(e=>e.loc[0]===field).map(e=>e.msg)
            
    }

    useEffect(() => {
        setValueState(value || {})
    }, [value])

    const fields = useMemo(() => {
        let newFields = [];
        if (schema) { 
            if (type==="object") {
                schema["properties"] && Object.getOwnPropertyNames(schema["properties"]).forEach(f => newFields.push(f))
                
            }

        }
        if (valueState) {
            Object.getOwnPropertyNames(valueState).forEach(f => (!newFields.includes(f)) && newFields.push(f))
        }
        if (value) {
            Object.getOwnPropertyNames(value).forEach(f => (!newFields.includes(f)) && newFields.push(f))
        }
        return newFields;

    }, [valueState, schema])

    const [editedField, setEditedField] = useState<string>()

    function modifyValue(modifyFucn) {
        let newValueState = Object.assign({}, valueState);
        modifyFucn(newValueState)
        setValueState(newValueState);
        onValueChange && onValueChange(newValueState)
    }
    function setValue(path, val) {
        let newValueState = Object.assign({}, valueState);
        let current = newValueState;
        let pathArr = path.split(".")
        pathArr.forEach((prop, i) => {
            if (i != pathArr.length - 1) {
                let newCurrent = current[prop]
                if (!newCurrent) {
                    newCurrent = {}
                    current[prop] = newCurrent
                } else {
                    newCurrent = Object.assign({}, newCurrent)
                    current[prop] = newCurrent
                }
                current = newCurrent
            }
        })
        current[pathArr[pathArr.length - 1]] = val
        setValueState(newValueState);
        onValueChange && onValueChange(newValueState)
    }

    


    function evaluateShowCondition(show_condition) {
        if (show_condition) {

            let not_met_found =  Object.getOwnPropertyNames(show_condition).find(f =>!(valueState[f] == show_condition[f] || (!!valueState[f] ===  show_condition[f])))
            if (not_met_found) {
                return false
            }
        }

        return true;
    }

    function getFieldTitle(field:string, customSchema?: JSONSchema) {
        return (customSchema||schema)?.properties && (customSchema||schema)?.properties[field]?.title || field
    }

    function canRemoveKey(field:string, customSchema?: JSONSchema) {
        return !readOnly &&  !((customSchema||schema)?.properties && (customSchema||schema)?.properties[field] )// if field is not specified in the schema
    }

    function getFieldSchema(field:string, customSchema?: JSONSchema) {
       try{
        
        const theSchema=(customSchema||schema);
        let result =  theSchema?.properties && theSchema?.properties[field] 
        if (!result && theSchema?.additional_properties && typeof(theSchema?.additional_properties)==="object" )
            result = ( theSchema?.additional_properties)
        if (result?.type===undefined && result?.allOf) {
            //try to find refs:
            if (result.allOf.length>0) {
                let ref = result.allOf[0]["$ref"]
                if (ref && ref.startsWith("#/")) {
                    let current = theSchema;
                    for (let part of ref.split("/")){
                        if (part==="#") {
                            current =theSchema;
                        }
                        else {
                            current = current[part]
                            if (!current) break;

                        }

                    }
                    if (current) return current;
                }

            }
        }
        return result;
       }catch(e){
            console.error(e)
            return undefined;
        }
    }

    function getFieldType(field){
        if (value && value[field]) return Array.isArray(value[field])?"array":  typeof(value[field])
        else
        return getFieldSchema(field)?.type || "string"
    }

    

    return (
        <Box  width="100%">
            
            <table  className={"autoUIGrid " + size}>
                <tbody>
                    {fields && fields
                        .filter(field =>
                            evaluateShowCondition(schema?.showCondition) && !(schema?.properties && schema?.properties[field]?.hidden === true)
                        )
                        .filter(field =>{
                             return !excludeFields || !excludeFields.includes((parentPath?`${parentPath}.`:"")+field)
                            })
                        .map((field, i) => {


                            const fieldSchema = getFieldSchema(field)
                            const fieldType =getFieldType(field)
                            const fieldErrors= getFieldErrors(field)
                            return ( fieldType==="object")   ?
                                (<tr key={i}>
                                    <td colSpan={2}>
                                        
                                        <Flex p="15px 0px 5px">
                                            
                                            <Tooltip label={fieldSchema?.description} placement="top-start" >
                                                <Flex>

                                                    <Text className="nowrap"  fontSize={size || "sm"} fontWeight={800} >{getFieldTitle(field, schema)}</Text>
                                                    {fieldSchema?.description && <Text fontSize="0.8em" fontWeight={900}>?</Text>}
                                                </Flex>
                                            </Tooltip>
                                            
                                            {/* {canRemoveKey(field) && (
                                                <RemoveKeyButton onClick={() => {
                                                    modifyValue((val) => {
                                                        delete val[field]
                                                    })
                                                }
                                                } */}
                                                {canRemoveKey(field) && (
                                                    <IconButton size="12px" m="2px" variant='outline' aria-label='Search database' icon={<IoMdClose size="12px"/>} onClick={() => {
                                                       modifyValue((val) => {
                                                           delete val[field]
                                                       })
                                                   }}
                                                />
                                            )}
                                        </Flex>
                                        <Box p="0px 0px 10px 20px" >
                                            {customEditors?.[field] || (
                                            <AutoUI
                                                value={valueState[field]}
                                                schema={fieldSchema}
                                                size={size}
                                                readOnly={readOnly||!onValueChange}
                                                type={schema?.properties?.[field]?.type}
                                                parentPath={parentPath?`${parentPath}.`:""+field}
                                                validationErrors={getChildErrors(field)}
                                                excludeFields={excludeFields}
                                                onValueChange={newVal => setValue(field, newVal)}
                                            />
                                            )}
                                            
                                            {fieldSchema?.additional_properties && (
                                                <Box>
                                                    {customEditors?.[field] || (
                                                    <FieldValue
                                                        size={size}
                                                        fieldValue={undefined}
                                                        schema={{ type:"string", description: "Add new key" }}
                                                        onEditStart={() => setEditedField(field)}
                                                        onEditFinish={(val) => {
                                                            val && setValue(field + "." + val, "")
                                                            setEditedField(undefined)
                                                        }}
                                                        isBeingEdited={editedField == field}
                                                    />
                                                    )}

                                                </Box>
                                            )}
                                        </Box>

                                    </td>
                                </tr>
                                ) :
                                (
                                    <tr key={i}>
                                        <td>
                                            <Box margin="0px 10px 0px 0px"  >

                                                <Tooltip label={fieldErrors?(fieldErrors.map(err=><Text >{err}</Text>)): fieldSchema?.description}  margin="0px 5px">
                                                    <Flex >
                                                   
                                                        {canRemoveKey(field) && (
                                                             <IconButton size="12px" m="2px" variant='outline' aria-label='Search database' icon={<IoMdClose size="12px"/>} onClick={() => {
                                                                modifyValue((val) => {
                                                                    delete val[field]
                                                                })
                                                            }}/>
                                                           
                                                        )}
                                                        <Text fontWeight={600} size={size || "sm"} textOverflow="ellipsis">
                                                           {getFieldTitle(field)}
                                                        </Text>
                                                        {fieldErrors?(
                                                            <BiMessageSquareError size="12px" color="red"/>
                                                        ):fieldSchema?.description && <Text fontSize="0.8em" fontWeight={900}>?</Text>}
                                                    </Flex>
                                                </Tooltip>

                                            </Box>
                                        </td>
                                        <td>


                                            {fieldSchema?.type==="array" && fieldSchema?.items  ? (
                                                <Box>
                                                    {value && value[field]?.map((v, i) => (
                                                        customEditors?.[field] || (
                                                        <FieldValue
                                                            size={size}
                                                            isReadOnly={readOnly||!onValueChange}
                                                            fieldValue={valueState[field][i]}
                                                            schema={fieldSchema}
                                                            onEditStart={() => setEditedField(field+"."+i)}
                                                            onEditFinish={(val) => {
                                                                if (val) {
                                                                    valueState[field][i] = val
                                                                    setValue(field, [...valueState[field]])
                                                                }
                                                                else {
                                                                    valueState[field].splice(i, 1)
                                                                    setValue(field, [...valueState[field]] )
                                                                }
                                                                setEditedField(undefined)
                                                            }}
                                                            isBeingEdited={editedField == field+"."+i}
                                                        />
                                                        )
                                                    ))}
                                                     {/* add new item placeholder  */}
                                                     {customEditors?.[field] || (<FieldValue
                                                        size={size}
                                                        fieldValue={undefined}
                                                        isReadOnly={(readOnly||!onValueChange)}
                                                        schema={{ type:"string",description: "Add new" }}
                                                        onEditStart={() => setEditedField(field+".new")}
                                                        onEditFinish={(val) => {
                                                            val && setValue(field, [...(valueState[field] || []), val])
                                                            setEditedField(undefined)
                                                        }}
                                                        isBeingEdited={editedField == field+".new"}
                                                    />
                                                    )}
                                                </Box>

                                            ) : (

                                                <Flex direction="row" align="start">
                                                    {/* standard edit vvvvvvvvvvvvvvvvvv*/}
                                                    {customEditors?.[field] || (
                                                    <FieldValue
                                                        size={size}
                                                        required={schema?.required?.includes(field)}
                                                        isReadOnly={readOnly||!onValueChange}
                                                        fieldValue={valueState[field]}
                                                        schema={fieldSchema}
                                                        onEditStart={() => setEditedField(field)}
                                                        onEditFinish={(val) => {
                                                            setEditedField(undefined)
                                                            if (val != valueState[field]) {
                                                                modifyValue(state => state[field] = val)
                                                            }
                                                        }}
                                                        isBeingEdited={editedField == field}
                                                    />
                                                    )}
                                                    {fieldSchema?.type!=="boolean" && schema?.required?.includes(field) &&<Tooltip label="Required field"><div className="asterix-div"> 
                                                        <Text className="asterix" fontWeight={900} fontSize="large" color={ !valueState[field]&&!(fieldSchema?.default)?"red":"darkgray"}>*</Text></div></Tooltip>}
                                                    {/* standard edit ^^^^^^^^^^^^^^^^^*/}
                                                   
                                                </Flex>
                                            )}
                                        </td>
                                    </tr>
                                )
                           }

                        )}
                </tbody>
            </table>
            {/* {value && JSON.stringify(value)} */}
        </Box>
    )
}


const FieldValue = ({ fieldValue, size,required, schema, isBeingEdited, onEditStart, onEditFinish, isLoading, isReadOnly }:{
    fieldValue:any,
    size?:string,
    required?:boolean
    schema?:JSONSchema,
    isBeingEdited?:boolean,
    onEditStart:()=>void,
    onEditFinish:(value:any)=>void,
    isLoading?:boolean
    isReadOnly?:boolean
}) => {
    const readOnly = isReadOnly || schema &&( schema["readOnly"] as boolean)
    const defaultNullPlaceholder = schema?.default || "undefined"
    let fieldSchema = schema || {}
    const [tempValue, setTempValue] = useState<any>("");
    useEffect(() => {
        setTempValue(fieldValue||fieldSchema.default||"")
    }, [fieldValue])
    const _onEditFinish = (e) => {
        onEditFinish && onEditFinish(tempValue)
    }
    const isBigText = (fieldSchema.maxLength && fieldSchema.maxLength > 1000)

    function getPlaceholderText(){
        if (readOnly) return defaultNullPlaceholder
        else if ( fieldSchema.default){
            return fieldSchema.default;
        }
        else if ( fieldSchema.example){
            
            return "e.q.: " + fieldSchema.example
        }
        else if (fieldSchema.description?.length<50){
            // we can use short description as placeholder
            return fieldSchema.description
        }
        else {return defaultNullPlaceholder}
    }

    const placeholderText = useMemo(()=>getPlaceholderText(),[fieldSchema])
    
    let isMultiline= isBigText && (fieldSchema?.type==="string" && !fieldSchema.format && !fieldSchema.one_of)

    function getEditElement() {
        if ((fieldSchema?.type==="string" && !fieldSchema.format && !fieldSchema.one_of)){
            
            if (!isBigText) {
                return <AutosizeTextInput 
                className={"textInput " + (size || "small")}
                value={tempValue}  placeholder={placeholderText} 
                
                onApply={onEditFinish}
                />
            }
            return (
                <AutosizeTextArea   placeholder={getPlaceholderText()}
                    maxWidth="100%"
                    value={tempValue}
                    maxHeight="70vh"
                    autoFocus
                    className={"textInput " + (size || "small")}
                    onApply={onEditFinish}
                />
            )
        }   
        else if (fieldSchema.one_of) {
            return (

                <Select 
                    size={size || "small"}
                    className={"textInput " + (size || "small")}
                    value={tempValue}
                    //open={true}
                    
                    onBlur={(e) => _onEditFinish(e)}
                    //onClose={(e) => _onEditFinish(e)}
                    onChange={(e) => {

                        setTempValue(e.target.value)
                        onEditFinish && onEditFinish(e.target.value)
                    }} >
                    {fieldSchema.one_of.map((option, i) => (
                        <option key={i} value={option}>{option}</option>
                        )
                    )}
                    </Select>

            )

        }
        else {
            return (
                <>edit is not supported</>
            )
        }

    }

    function validateValue(fieldSchema,value){
        if (!fieldSchema) return null;
        if (!fieldValue?.toString()) return null;
        if (fieldSchema.type==="string"){
            if (fieldSchema.maxLength && value.length>fieldSchema.maxLength) return `Value is too long. Max length is ${fieldSchema.maxLength}`
            if (fieldSchema.minLength && value.length<fieldSchema.minLength) return `Value is too short. Min length is ${fieldSchema.minLength}`
            if (fieldSchema.pattern && !value.match(fieldSchema.pattern)) return `Value is not in correct format. It must match this pattern: ${fieldSchema.pattern}`
        }
    }

    const error=useMemo(()=>validateValue(fieldSchema,tempValue),[fieldSchema,tempValue])
    
    let className=undefined
    if (fieldSchema?.type!=="boolean" && !isBeingEdited){
        let classes = [];
        if (readOnly)
            classes.push("readOnlyFieldValue")
        else{
            classes.push("fieldValue")
        }
        if (isBigText) classes.push("multiline")
        if (required) classes.push("required")
        if (error) classes.push("error")
        if (!fieldValue?.toString()) {
            if(fieldSchema?.default)
                classes.push("isDefault")
            else
                classes.push("empty")
        }

        className = classes.join(" ")
    }
    return (
        <Box width={isMultiline?"100%":undefined} >
        <Flex className={className}  direction="row" align="center" onClick={!readOnly?(() => !isBeingEdited && onEditStart()):undefined}>
            
            {
                isBeingEdited && fieldSchema?.type!=="boolean" ? (
                    getEditElement()
                ) :
                    (
                        (fieldSchema?.type==="boolean")?(
                            <Box margin="0px 5px">
                                 <Switch size='md' 
                                 colorScheme="brand"
                                 readOnly={readOnly}
                                        isChecked={fieldValue==undefined?fieldSchema.default:fieldValue}
                                        onChange={(e) => {
                                            setTempValue(e.target.checked)
                                            onEditFinish(e.target.checked)
                                        }}  />
                 
                            
                            </Box>
                        ):(
                                <Flex p="1px 5px" style={{maxWidth:"100%", overflow:"hidden"}}  align="start" className={"fieldValueContent" + (isBigText?" multiline":"")} >
                                <Text className="text" textAlign="start" fontSize={size || "sm"}  fontWeight={500} >
                                    {fieldValue?.toString()||getPlaceholderText()}
                                </Text> 
                                
                                </Flex>
                           
                        )
                    )
            }
            {!isBeingEdited && fieldSchema?.default && !fieldValue?.toString() && (
                <Box margin="0px 10px" style={{position:"relative", left:"8px", zIndex:90}} alignSelf="start" >
                    <Tooltip label="This is the default value, which will be used unless changed">
                    <Text fontSize="12px" fontWeight={500} color="gray">?</Text>
                    </Tooltip>
                </Box>
            )}
            {isLoading && (
                <Box margin="0px 10px">
                    <Spinner  size="sm" />
                </Box>
            )}
            {!readOnly && !isBeingEdited && fieldSchema?.type!=="boolean"? (
                <Flex margin="2px 0px 0px -15px"  className="editIcon" align="center" justify="end">
                    
                    <FiEdit  color="gray" size="15px" style={{position:"relative", left:"20px"}}/>
                    
                </Flex>
            ) : (
                <></>
            )
            }

        </Flex>
        <Box width="50px"  >
        <Text  color="red"  fontSize="10px">{error}</Text>

        </Box>
        </Box>
    )
}

//                           

