

import { useEffect, useState } from "react";



import { getApi } from "../apiService";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import LoginComponent from "../components/Login/loginBox";
import { useAppContext } from "../appContext";
import { Logo } from "../components/CommonIcons/commonIcons";
import { Box, Button, Flex, Spinner } from "@chakra-ui/react";
import { IconFaceIdError } from "@tabler/icons-react";


export  default function IntegrationRedirectPage(){
    
    

    const navigate = useNavigate()
    const [queryParams, setQueryParams] = useSearchParams()
    const {connector}=useParams()
    const [success,setSuccess]=useState<boolean>()
   
    useEffect(()=>{
            if (queryParams){

                getApi().connectIntegrationCallback(connector,queryParams).then((res)=>{
                    if (res.success){
                        const parentWindow = window.opener;
                        setSuccess(true)

                        parentWindow.postMessage(JSON.stringify({connector, refresh:true}), '*');
                        window.close()
                    }
                }).catch((err)=>{
                    setSuccess(false)
                    console.error(err)
                })
            }
        
    },[queryParams])
    


    return (
        <Flex  justify="stretch" align="center" direction="column" className="general-page" height="100vh"  overflow="auto">
            <Flex flexGrow={0} alignSelf="start">
            <Box  onClick={()=>navigate("https://www.geniously.ai")} p="10px">
                <Logo className="logo-icon" size={"large"} withText/>
            </Box>
            </Flex>
            <Flex direction="column" align="center" >


            {success==true ? <Button onClick={()=>window.close()} colorScheme="brand" size="lg">You may close this window now</Button>:(
                (success!=false?<Spinner size="xl"/>:(
                    <IconFaceIdError size="50px"/>
                ))
            )}
            
            </Flex>
        </Flex>
    )
}