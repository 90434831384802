import { Box } from "@chakra-ui/react";
import { AiOutlineFileSearch } from "react-icons/ai";
import { ImSearch } from "react-icons/im";
import { IconBox } from "../IconBox/iconBox";
import { BiSupport, BiWrench } from "react-icons/bi";
import { HiBolt } from "react-icons/hi2";
import { FaRegCompass } from "react-icons/fa";
import { TbClipboardText } from "react-icons/tb";
import { GrDocumentPdf } from "react-icons/gr";

import { IconWorldSearch,IconDatabaseSearch, IconApi, IconPlugConnected, IconHomeSearch, IconClipboardText } from '@tabler/icons-react';
import { IconCompass } from "@tabler/icons-react";


interface DataSourceIconProps {
    plain?: boolean;
    type: string;
    size?: "small" | "medium" | "large" | "xlarge";
    [key:string]:any
}

const INDEXED_TYPES=[
    "text_snippet",
    "pdf_files",
    "website",
    "sitemap"
]

export const DataSourceIcon = (props:DataSourceIconProps) => {
    function getSkillColor(type:string){
        if (INDEXED_TYPES.includes(type)) return "cyan.500"
        else return "purple.500"
    }

    
    function getIcon(type:string){
        if (type === "text_snippet") return <IconClipboardText/>
        else if (type === "pdf_files") return <GrDocumentPdf/>
        else if (type === "website") return <IconHomeSearch/>
        else if (type === "sitemap") return <IconCompass/>
        
        else if (type === "internet_search") return <IconWorldSearch/>
        else if (type === "remote_sql") return <IconDatabaseSearch/>
        else if (type === "remote_rest_api") return <IconApi/>
        else if (type === "odata") return <IconApi/>
        
        else return <IconPlugConnected/>
    }
    return <IconBox  color={getSkillColor(props.type)} icon={getIcon(props.type)} {...props}/>
}