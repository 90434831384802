



import { useMemo } from "react"
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom"
import { useAppContext } from "../../appContext"
  

import "./appSidebar.css"
import { MdDashboard, MdOutlineDashboard, MdOutlineSpaceDashboard } from "react-icons/md"

import UserAvatar from "../UserAvatar/userAvatar"
import { Box, Flex, HStack, Stack, Text, Tooltip } from "@chakra-ui/react"
import { IconArrowsLeftRight, IconBooks, IconBulb, IconLock } from "@tabler/icons-react"
import { IconBook2 } from "@tabler/icons-react"
import { AiTwotoneExperiment } from "react-icons/ai"
import { IconSparkles } from "@tabler/icons-react"
import { IconHistory } from "@tabler/icons-react"
import { IconWand } from "@tabler/icons-react"
import { IconSettings } from "@tabler/icons-react"
import { IconMessages } from "@tabler/icons-react"
import { IconSwitchHorizontal } from "@tabler/icons-react"
import { IconLayout2 } from "@tabler/icons-react"

const BORDER = "1px solid rgba(0,0,0,0.15)"


export function AppSidebar({ children }: {
    children?: any
    [key: string]: any
}) {
    const currentUser=null
    //const {currentUser} = useAppContext()
    const [searchParams,setSearchParams]=useSearchParams()
    //const tempApiKey = useMemo(()=>searchParams?.get("temp-api-key"),[searchParams])
    


    const pathProps = useParams()
    const navigate = useNavigate()

    
    return (
        <Stack  width="100%"  className="appSidebar" justify="between"  height="100vh" >
            <Flex direction="column"  justify="stretch" height="100%">
 
              
                
                <Flex p="10px" borderRight={BORDER}/>
                <Stack  spacing={0}>
                
                    {children ||(
                        <>
                        <AppSidebarButton icon={<IconLayout2  />} name="Home" path={"/apps"}/>  
                        {pathProps.appId&&(
                            <>
                            <AppSidebarButton icon={<IconSettings/>} name="Setup" path={("/app/:appId/setup")}/>
                            
                            <AppSidebarButton icon={<IconMessages/>} name="Conversations" path={("/app/:appId/conversations")}/>
                            <AppSidebarButton icon={<IconBulb/>} name="Insights" path={("/app/:appId/insights")}/>
                            <AppSidebarButton icon={<IconSwitchHorizontal/>} name="Integrations" path={("/app/:appId/integrations")}/>
                            
                            <AppSidebarButton icon={<AiTwotoneExperiment size="25px" />} name="A/B testing" path={("/unit-tests")} disabled tooltip="coming soon"/>
                        </>
                        )}
                        {/* <AppSidebarButton icon={<StatisticsIcon size="large" className="sidebar-icon" />} name="Statistics" path="/statistics" /> */}

                    </>
                    )}
                
                </Stack>
                <Flex direction="column" justifySelf="stretch" flex={1} borderRight={BORDER}  p="20px"/>
            </Flex>
            <Box margin="0px 0px 10px">
                <Box >
                {currentUser&&(
                    <AppSidebarButton icon={<UserAvatar />}  tooltip="Account settings" path="/settings" />
                )}
                </Box>
                {/* <AppSidebarButton icon={<SettingsIcon size="large" className="sidebar-icon" />} name="Settings" path="/settings" /> */}
            </Box>
        </Stack>
    )

}

export function AppSidebarButton({ icon, onClick, path, name, tooltip , disabled}: {
    icon: any
    onClick?: () => any
    path:string
    name?: string
    tooltip?: string
    disabled?:boolean
}) {


const location = useLocation()
const navigate = useNavigate()
const pathProps = useParams()
const active = useMemo(()=>location && ((location.pathname+location.search).includes(path?.replace(/:(\w+)/g, (_, key) => pathProps[key]))),[location])
const [searchParams,setSearchParams]=useSearchParams()

function _onClick(){
    if (!disabled){
        if (path){
            let finalPath = path.replace(/:(\w+)/g, (_, key) => pathProps[key])
            navigate(finalPath)
        }
        if (onClick){
            onClick()
        }
    }
}


    return (
        // <Tip plain dropProps={{align:{left:"right"}}} content={
        //     <Box 
            
        //     className="sidebar-button-tip"  pad="10px" round="0px 10px 10px 0px" focusIndicator={false} >
        //         <Text>

        //         {(tooltip || name)}
        //         </Text>
        //     </Box>
        //     }>

            <Tooltip label={tooltip} placement="right" >
            <HStack align="center" 
            borderLeftRadius={10}
            marginLeft={2}
            
            
            border={active?BORDER:undefined}
            borderRight={active?"0px":BORDER}
            gap="5px" onClick={()=>_onClick()} background={active?"gray.50":undefined} 
            color={active?"brand.900":(disabled?"gray.500":undefined)}
            p="8px 8px 8px 12px" cursor="pointer" _hover={{background:"gray.50", color:"brand.300"}}
            justify="space-between"
            >
                <HStack marginRight={5}>

                <Box width="25px" height="25px">{icon}</Box>
                {name && <Text   fontWeight={900} fontSize="xs">{name}</Text>}
                </HStack>
                {disabled&&( <Box alignSelf="end" width="25px" height="25px" color="gray.500"><IconLock size="18px"/></Box>)}
            </HStack>
            </Tooltip>
    )

}
