import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, HStack, Spinner, Stack, Text } from "@chakra-ui/react";
import { ImSearch } from "react-icons/im";
import { Command, DataSource, MetaType, Skill } from "../../models/dataModel";
import { useEffect, useMemo, useState } from "react";
import { AutoUI } from "../AutoUI/AutoUI";
import { getApi } from "../../apiService";
import useSWR from 'swr'
import { EditModal } from "../ModalDialogs/editModal";
import { CommandTypeBox } from "./commandTypeBox";
import { IconPlus } from "@tabler/icons-react";
import { CommandEdit } from "./commandEdit";
import { BiPlusMedical } from "react-icons/bi";





export const CommandsSection = ({commands, onChange}:{
    commands:Command[], 
    onChange:(value:Command[])=>any
    
}) => {

    const {data:availableCommandTypes, isLoading:availableCommandTypesLoading} = useSWR(`availableCommandTypes`,()=> getApi().getMetaSchemaTypes("commands"))
    
    //const [value, _setValue] = useState(commands)
    
    const [isLoading, setIsLoading] = useState(false)
    
    const [modal, setModal] = useState<any>(null)

 return(

     <Box >
         {modal}
        {isLoading?(<Spinner/>):(
            <Stack>
            
            {commands?.map((command)=>{
                return {
                    command:command,
                    type:availableCommandTypes?.find((type)=>type.name==command.type)
                }
            }).map(({command,type},i)=>(
                <CommandTypeBox 
                    key={i}
                    type={type?.name} text={command.name||type?.title} description={command.description||type?.description} onClick={()=>{
                        setModal(<EditModal
                            caption={"Edit command settings"}
                            onOk={(val)=>{
                                onChange(commands.map((c)=>c==command?val:c))
                                setModal(undefined)
                            }}
                            onCancel={()=>setModal(undefined)}
                            value={command}
                            >
                              {  (val, setVal)=><CommandEdit value={val} onChange={setVal} /> }
                            </EditModal>
                            )
                    }}
                    onDelete={()=>onChange(commands.filter((c)=>c!=command))}
                    
                    />
            ))}
            <AddCommandButton availableCommandTypes={availableCommandTypes} onAddCommand={(command)=>{
                onChange([...(commands || []), command])
            }}/>

            </Stack>
            
        )}
    </Box>
      )  


}

const AddCommandButton=({availableCommandTypes, onAddCommand}:{
    availableCommandTypes:MetaType[]
    onAddCommand(command:Command):any
})=>{

    const [modal, setModal] = useState<any>(null)
    const _addCommand = (command_type:string)=>{
        const command = {type:command_type, configuration:{}}
        setModal(<EditModal
            caption={"Add new command"}
            onOk={(val)=>{
                onAddCommand(val)
                setModal(undefined)
            }}
            onCancel={()=>setModal(undefined)}
            value={command}
            >
              {  (val, setVal)=><CommandEdit value={val} onChange={setVal} /> }
            </EditModal>
            )
        onAddCommand(command)
    }

    return (
        <Stack>
            {modal}
        <Accordion allowToggle index={undefined} >
        <AccordionItem>
            <h2>
            <AccordionButton p="8px">
                <HStack  textAlign='left'>
                    <Box > 
                        {/* // borderRadius={5} border="1px solid gray" p="4px" > */}

                    <BiPlusMedical color="gray" size={20}/>
                    </Box>
                <Text fontWeight={900} color="gray">Add a command</Text>
                </HStack>
                <AccordionIcon />
            </AccordionButton>
            </h2>
            <AccordionPanel p="0px">
            <Stack margin="10px 2px">
            {availableCommandTypes?.map((type)=>(
                <CommandTypeBox add={true} type={type.name} active={false} text={type.title||type.name} description={type.description} onClick={()=>_addCommand(type.name)}/>
            ))}
            </Stack>
            </AccordionPanel>
        </AccordionItem>


        </Accordion>

    </Stack>
    )
}

