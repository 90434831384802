import { Box, CloseButton, Flex, Stack, Text } from "@chakra-ui/react";
import { DataSourceIcon } from "./dataSourceIcons";
import { type } from "os";
import { DataSource } from "../../models/dataModel";
import { BiPlusMedical } from "react-icons/bi";
import { DeleteWithConfirm } from "../DeleteWithConfirm/deleteWIthConfirm";



export function DataSourceCaption({
    text,
    description,
    type
}:{
text:string,
description?:string,
type:string
}) {
  
  return (
    <Flex className="data-source-box"  align="center"  justify="stretch" flexGrow="1" border="1px solid transparent" borderRadius={5} p="5px" 
    >
    <Stack direction="row" align="center" flexGrow="1 "  borderRadius={5} >
        <DataSourceIcon size={"small"} type={type}/>
        <Flex align="start" direction="column">
            <Text margin="0" lineHeight="1em" fontWeight={900} fontSize="sm">{text}</Text>
            {description && <Text  margin="0" lineHeight="1em"  fontSize={"xs"}  color="gray.900">{description}</Text>}
            
        </Flex>
    </Stack>
    
        </Flex>
  );
}
