

import { useEffect, useState } from "react";



import { getApi } from "../apiService";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import useSWR from 'swr'

import { useAppContext } from "../appContext";
import { Box, Button, Card, CardBody, CardFooter, CardHeader, Divider, Flex, HStack, Heading, Image, SimpleGrid, Spinner, Switch, Text, VStack, Wrap, WrapItem } from "@chakra-ui/react";


import { IconPlugConnected, IconWebhook } from "@tabler/icons-react";
import { IconApi } from "@tabler/icons-react";

import { IconArrowsExchange2 } from "@tabler/icons-react";
import { IconSettingsCode } from "@tabler/icons-react";

const IntegrationBox=({id, name, icon,description, color, active,settings, onOpenDetail, onConnect,onRefresh}:{
    id:string,
    name:string,
    description?:string,
    color?:string,
    icon:any,
    settings:any,
    active?:boolean,
    onOpenDetail:()=>any
    onConnect?:()=>any
    onRefresh?:()=>any
})=>{
    const {appId}=useParams()
    const defaultOnConnect=()=>{
        
        getApi().connectIntegration(id).then(({url}:any)=>{
            const popupWindow = window.open(url, 'nla', 'width=650,height=700')
            //window.open(url, '_blank').focus();
            window.addEventListener('message', (event) => {
                console.log(event)
                // Check if the message is from the popup window
                if (onRefresh && event.source === popupWindow ) {
                  // Handle the message
                  if (JSON.parse(event.data).refresh){
                      onRefresh();
                  }
                }
              });
        })
    }

    
const _active=active!==undefined?active : Boolean(settings)
return (
    <Card onClick={onOpenDetail} cursor="pointer" p="15px" borderRadius="10px" boxShadow="md" 
    border="2px solid white" rounded={10} 
    //bgGradient="linear(to-b, white, gray.50)"
    bg="white"
    >
        <CardBody p="5px" width={["100%","35vw"]}>
        <HStack>
            <Box border="1px solid lightgray" padding="3px"  rounded={8}>
            <Box bgColor={color} rounded={5} p="5px"  minHeight="40px" >
            {typeof icon === "string"?(
            <Image  src={icon}  height="40px"/>
            ):(
                icon
            )}
            </Box>
            </Box>
            <VStack align="start">
            <Heading size='md'> {name}</Heading>
            <Text fontWeight={500}>{description}</Text>
            </VStack>
        
        </HStack>
        </CardBody>
        <Divider orientation='horizontal' />
        <CardFooter  p="5px">
            <Flex fontSize="sm" fontWeight={500} color="blackAlpha.600" justify="space-between" width="100%" align="end">

            <Button leftIcon={!_active?<IconPlugConnected size="20px"/>:<IconSettingsCode size="20px"/>}  colorScheme='brand' size="xs" variant={!_active?"outline":"solid"}
            onClick={()=>!_active?(onConnect||defaultOnConnect)():onOpenDetail()}
            >{!_active?"Connect":"Open settings"}</Button>
            <Switch size="sm" colorScheme="brand" isChecked={_active} onChange={()=>(onConnect )()}/>
            </Flex>
        </CardFooter>
    </Card>
)
}


export  default function IntegrationsPage(){
    
    
    const {appId}=useParams()
    const navigate = useNavigate()
    const [searchParams, setSearchParams] = useSearchParams()

   
    const loadData = ()=>{
        
        
        return getApi().getActiveIntegrations().then((res)=>{
            return res
        })

    }

   

    const {data:activeIntegrations, isLoading} = useSWR(`apps`,()=>loadData())


    


    return ( <Flex direction="column">
        {isLoading?(
            <Box p="40px" alignSelf="center">
                <Spinner size="xl"/>
            </Box>
        ):(

        
            <Wrap className="abc" spacing="10px" margin={["15px","30px" ]}>
                <IntegrationBox
                id="webhooks"
                name="Webhooks"
                icon={<IconWebhook color="white" size="40px"/>}
                color="cyan.500"
                settings={Boolean(activeIntegrations?.find(t=>t.connector=="webhooks"))}
                onOpenDetail={()=>{}}
                />
                <IntegrationBox
                id="rest"
                name="Rest API"
                icon={<IconApi color="white" size="40px"/>}
                color="cyan.500"
                settings={Boolean(activeIntegrations?.find(t=>t.connector=="rest"))}
                onOpenDetail={()=>{}}
                />
                {/* <IntegrationBox
                name="Shopify"
                icon={"https://cdn3.iconfinder.com/data/icons/social-media-2068/64/_shopping-1024.png"}
                active={false}
                onOpenDetail={()=>{}}
                /> */}
                <IntegrationBox
                id="zapier"
                name="Zapier"
                description="Connect Geniously to 5000+ apps via Zapier"
                icon={"/assets/zapier-logo.png"}
                settings={Boolean(activeIntegrations?.find(t=>t.connector=="zapier"))}
                onOpenDetail={()=>{}}
                />

                <IntegrationBox
                id="ms_outlook"
                name="MS outlook"
                description="Connect Microsoft Outlook 365"
                icon={"https://upload.wikimedia.org/wikipedia/commons/thumb/d/df/Microsoft_Office_Outlook_%282018%E2%80%93present%29.svg/2203px-Microsoft_Office_Outlook_%282018%E2%80%93present%29.svg.png"}
                settings={Boolean(activeIntegrations?.find(t=>t.connector=="ms_outlook"))}
                onOpenDetail={()=>{}}
                />
            </Wrap>

            
        )}
    </Flex>
    
    )
}