import { Badge, Box, ButtonGroup, CloseButton, Flex, IconButton, Popover, PopoverArrow, PopoverBody, PopoverCloseButton, PopoverContent, PopoverHeader, PopoverTrigger, Progress, Stack, Text, useToast } from "@chakra-ui/react"


const NotificationBox=({notification, onClose}:{
    notification:StatusUpdate,
    onClose?:()=>void
})=>{

    let status = "Running";
    let statusColor = undefined
    if (notification.status_type=="ERROR"){
        status="Error"
        statusColor="red"
    }
    else if (notification.status_type=="WARNING"){
        status="Warning"
        statusColor="orange"
    }
    else if (notification.status_type=="STOPPED"){
        status="Stopped"
    }
    else if (notification.status_type=="STOPPING"){
        status="Stopping"
    }
    else if (notification.status_type=="INFO"){
        if (notification.is_running)
            status="Running"
        else{
            status="Done"
            statusColor="green"
        }
    }
    
    return ( <Box borderRadius={5} border="1px solid lightgray" p="0px 5px 5px" color="blackAlpha.700" top="25px" background="white">
        <Stack direction="row" align="center" justify="space-between"  overflow="hidden"  width="100%"  p="8px 0px">
            <Text fontWeight="bold" textAlign="start" width="100%" overflow="hidden" textOverflow="ellipsis"  lineHeight="1em" whiteSpace="nowrap"  _hover={{whiteSpace:"unset"}}>
                {notification.process_name}
            </Text>
            <Flex direction="row" align="center">

                <Badge colorScheme={statusColor}>{status}</Badge>
                {onClose && <CloseButton onClick={()=>onClose()}/>}
            </Flex>
        </Stack>
        <Box p="5px">
        {notification.stage_name &&
        <Flex justify="start" >
            <Text fontWeight="bold" fontSize="xs" textAlign="start" lineHeight="1em">
                {notification.stage_name}
            </Text>
            
        </Flex>
        }
        {notification.status_message &&
        <Box width="100%" overflow="hidden" >
            <Text fontSize="xs" textAlign="start"  lineHeight="1em" whiteSpace="nowrap" textOverflow="ellipsis"  _hover={{whiteSpace:"unset"}}>
                {notification.status_message}
            </Text>
        </Box>
        }
        </Box>
        <Progress size="xs" value={notification.progress} isIndeterminate={notification.is_running && (notification.progress==null || notification.progress==undefined)} />
    </Box>)
}
       
export default NotificationBox