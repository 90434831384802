import  { useState, useRef, useEffect } from "react";
import { Textarea } from '@chakra-ui/react'




export function AutosizeTextArea({value, onValueChange,onApply, ...props}:{
    value: string,
    onValueChange?: (value:string)=>void,
    onApply?: (value)=>void,
    [key:string]:any
}) {
  const [val, setVal] = useState(value);
  const [initialSize, setInitialSize] = useState<any>();
  const textAreaRef = useRef(null);

  const resizeTextArea = () => {
    //textAreaRef.current.style.height = "auto";
    var _initial_size =initialSize|| {height:textAreaRef.current.scrollHeight +15 , width:textAreaRef.current.scrollWidth +25  }
    if (!initialSize){
      setInitialSize(_initial_size)
    }
    textAreaRef.current.style.height = Math.max(_initial_size?.height||0, textAreaRef.current.scrollHeight) + "px";
    //textAreaRef.current.style.width = Math.max(_initial_size?.width||0, textAreaRef.current.scrollWidth) + "px";
  };

  useEffect(resizeTextArea, [val]);

  const _onChange = e => {
    setVal(e.target.value);
  };

  return (
    
      <Textarea ref={textAreaRef} size="xs" fontSize="sm" value={val} onChange={_onChange} rows={1} width="100%"  maxWidth="80vw" {...props} 
      // onKeyDown={(e)=>{
      //   e.key === "Enter" && onApply && onApply(val)
      // }}
      rounded={4}
      onBlur={()=>onApply && onApply(val)}
      />
    
  );
}


