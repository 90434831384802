import { Box, Button, Flex, FormControl, FormLabel, HStack, Heading, Image, Input, Link, PinInput, PinInputField, Stack, Text, VStack } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { firebaseApp } from '../../firebase/firebaseApp';
import "./loginBox.css"
import { Link as ReactRouterLink } from 'react-router-dom'
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signInWithPopup, sendPasswordResetEmail,
  GoogleAuthProvider, GithubAuthProvider
} from "firebase/auth";
import { useAppContext } from '../../appContext';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { FcGoogle } from 'react-icons/fc';
import { FaGithub } from 'react-icons/fa';
import { getApi } from '../../apiService';

const LoginComponent = ({ mode }: {
  mode: "login" | "signup"
}) => {
  const [email, setEmail] = useState('');

  const [password, _setPassword] = useState('');
  const [password2, _setPassword2] = useState('');
  const [error, setError] = useState('');
  const [verificationCode, setVerificationCode] = useState<string | undefined>(undefined)
  const [inviteInfo, setInviteInfo] = useState<{ invite_id: string }>(undefined)
  const [wrongPassword, setWrongPassword] = useState<boolean | string>(false);
  const [searchParams, setSearchParams] = useSearchParams()
  const { currentUser, setCurrentUser } = useAppContext()
  const navigate = useNavigate()
  

  useEffect(() => {
    if (searchParams && searchParams.get("invite-id")) {
      const invite_id = searchParams.get("invite-id")
      const _email = searchParams.get("email")
      if (!_email) {
        setError("Incomplete invite link")
        return
      }
      
     

      getApi().testInvite(_email, { invite_id }).then(res => {
        setInviteInfo(res)
      }).catch(error => setError(error.message))

    }
  }, [searchParams])

  useEffect(() => {
    setError(undefined)
  }, [mode])

  const verifyEmailFormat = (email: string) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  }

  useEffect(() => {
    if (verificationCode && verificationCode.length == 4) {
      if (email && verifyEmailFormat(email)) {

        getApi().testInvite(email, { invite_verification_code: verificationCode }).then(res => {
          setInviteInfo(res)
          setError(res ? undefined : "Invalid verification code or email")
        }).catch(error => setError(error.message))
      }
      else {
        setError("Please enter your email")
      }
    }
  }, [verificationCode, email])

  const setPassword = (val: string) => {
    _setPassword(val)
    if (password2 && val && val != password2) {
      setError("Password do not match")
    }
    else {
      setError("")
    }
  }

  const setPassword2 = (val: string) => {
    _setPassword2(val)
    if (password && val && val != password) {
      setError("Password do not match")
    } else {
      setError("")
    }
  }




  const handleRegisterWithProvider = (provider) => {
    const auth = getAuth(firebaseApp);

    setError("")
    signInWithPopup(auth, new provider())
      .then((result) => {
        console.log(result)
        // This gives you a provider Access Token. You can use it to access the provider API.


        // The signed-in user info.
        const user = result.user;
        setCurrentUser(user)


        // IdP data available using getAdditionalUserInfo(result)
        // ...
      }).catch((error) => {
        console.error(error)
        const errorMessage = error.message;
        setError(errorMessage.replace("Firebase: ", ""))
        // ...
      });
  };

  const handleResponseTypeUrlParams = (currentUser) => {
    if (currentUser) {
        currentUser.getIdToken().then(token=>{
      
        fetch((process.env.REACT_APP_API_URL || "http://localhost:4080") + "/oauth2/authorize/code",{
          headers:{authorization: 'Bearer ' + token}
        }).then(res=> res.json()).then(res=>{
          if (res.code){

            const redirect_query_param = new URLSearchParams(searchParams)
            redirect_query_param.set("code",res.code)
            
            const redirect_url = redirect_query_param.get("redirect_url")
            redirect_query_param.delete("redirect_url")
            
            window.location.href = (redirect_url+"?"+redirect_query_param.toString())
          }
          else{
            setError("Error getting access token")
            console.error(res)
          }
          
        })

      })
    }
  }


  const login = (auth = undefined, onError?: () => any) => {
    if (!email) {
      setError("Please fill up your email")
      return
    }
    if (!password) {
      setError("Please fill up your password")
      return
    }
    const _auth = auth || getAuth(firebaseApp);
    setError("")
    return signInWithEmailAndPassword(_auth, email, password).then(userCredential => {

      return getApi().getMyAccount().then((res) => {
        setCurrentUser(userCredential.user)
        
        if (searchParams.get("response_type")!=="code"){
          navigate("/home")
        }
        else{
          handleResponseTypeUrlParams(userCredential.user)
          
        }
      })
        .catch((err) => {
          setError("Account has't been activated properly. Please contact support.")
          onError && onError()
        })

    }).catch(error => {
      setError(error.message.replace("Firebase: ", ""))
    })
  }




  const handleEmailRegister = async (event: React.FormEvent<HTMLFormElement> = undefined,) => {

    if (!email) {
      setError("Please fill up your email")
      return
    }
    if (!password) {
      setError("Please fill up your password")
      return
    }

    event?.preventDefault();
    const activateAccount = (user) => getApi().signup({
      email,
      invite_id: inviteInfo?.invite_id,
      account_id: user.uid,
    }).then(() => {
      setCurrentUser(user)
      const followup = searchParams.get("followup")
      navigate(followup || "/home")
    })
      .catch(error => {
        setError(error.message)
      })

    const auth = getAuth(firebaseApp);
    if (mode == "login") {
      login().catch(error => {
        setError(error.message.replace("Firebase: ", ""))
        if (error.code = "auth/wrong-password") {
          setWrongPassword(true)
        }
      })
    }
    else {
      if (!password2) {
        setError("Please re enter your password")
        return
      }
      if (password !== password2) {
        setError("Passwords do not match")
        return
      }
      createUserWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
          activateAccount(userCredential.user)
        })
        .catch((error) => {
          const errorMessage = error.message;
          if ("auth/email-already-in-use") {
            login(auth, () => {
              signInWithEmailAndPassword(auth, email, password).then(userCredential => {
                activateAccount(userCredential.user)
              }
              ).catch(error => {
                setError(error.message.replace("Firebase: ", ""))
              })
            }
            )

          }
        });
    }
  }



  return (
    <Box className='main' >

      <Flex direction="row" width="100%" justify="stretch" align="center" flexWrap="wrap" minH="90vh" overflow="hidden">
        <Flex
          p={["10px","40px"]}
          align={'center'}
          justify={'center'}
        >
          <Stack spacing={8} mx={'auto'} maxW={'lg'} py={12} px={6} boxShadow={'2xl'} rounded="lg" borderWidth="1px"
            background="white"
            alignSelf='center'
            width={["95vw","85vw","500px"]}
            zIndex={1}
          >
            <Stack align={'center'}>

              <Heading fontSize={'4xl'}>{mode == "login" ? "Login in to your account" : "Create new account"}</Heading>
              {mode == "signup" && <Text fontSize={'lg'} color={'gray.600'}>
                sign up to enjoy all of our cool features
                {/* <Link color={'blue.400'} >features</Link> ✌️ */}
              </Text>}
            </Stack>
            <Box
              rounded={'lg'}
              p={[1,8]}>
              <Stack spacing={4} >
                
                <form onSubmit={(e) => {
                  e.preventDefault()
                  mode == "login" ? login() : handleEmailRegister()
                }}>
                  <FormControl id="email">
                    <FormLabel>Email address</FormLabel>
                    <Input type="email" value={email} onChange={e => setEmail(e.target.value)} />
                  </FormControl>
                  <FormControl id="password">
                    <FormLabel>Password</FormLabel>
                    <Input type="password" value={password} onChange={e => setPassword(e.target.value)} />
                  </FormControl>

                  {mode == "signup" && (

                    <FormControl id="password2">
                      <FormLabel>Repeat password</FormLabel>
                      <Input type="password" value={password2} onChange={e => setPassword2(e.target.value)} />
                    </FormControl>
                  )}
                  {mode == "signup" && verificationCode !== undefined && (

                    <FormControl id="verification_code">
                      <FormLabel>Invite verification code</FormLabel>
                      <HStack spacing={2} justify="center">
                        <PinInput value={verificationCode} onChange={setVerificationCode} >
                          <PinInputField backgroundColor={inviteInfo ? "green.100" : undefined} />
                          <PinInputField backgroundColor={inviteInfo ? "green.100" : undefined} />
                          <PinInputField backgroundColor={inviteInfo ? "green.100" : undefined} />
                          <PinInputField backgroundColor={inviteInfo ? "green.100" : undefined} />
                        </PinInput>
                      </HStack>
                    </FormControl>
                  )}

                  <Stack spacing={0} m="10px 0px">
                    <Stack
                      direction={{ base: 'column', sm: 'row' }}
                      align={'start'}
                      justify={'space-between'}>


                    </Stack>
                    <VStack spacing={2} align="stretch">
                      {error && <Text color="red.700">{error}</Text>}
                      <Button
                        colorScheme={'brand'}
                        type="submit"
                        //onClick={() => mode == "login" ? login() : handleEmailRegister()}
                      >
                        {mode == "login" ? "Continue" : "Sign up"}
                      </Button>

                      <Button
                        colorScheme={'brand'} variant="outline"
                        onClick={() => {
                          handleRegisterWithProvider(GoogleAuthProvider)
                        }}
                        leftIcon={<FcGoogle />}
                      >

                        {mode == "login" ? "Login with Google" : "Sign up with Google"}
                      </Button>
                      {mode == "signup" && verificationCode === undefined && <Link as={Text} alignSelf="center" color={'gray.400'} fontSize="xs" onClick={() => setVerificationCode("")} >Have an invite?</Link>}
                      {mode == "login" && <Link as={ReactRouterLink} to="/password-reset" alignSelf="center" color={'blue.400'}  >Forgot password?</Link>}
                    </VStack>
                  </Stack>
                </form>
              </Stack>
            </Box>
            {mode == "login" ? <Link as={ReactRouterLink} to="/signup" alignSelf="center" color={'blue.400'}  >Don't have an account? Sign up</Link> :
              <Link as={ReactRouterLink} to="/login" alignSelf="center" color={'blue.400'}  >Already have an account? Login</Link>}
          </Stack>
        </Flex>
      </Flex>
    </Box>
  );
};

export default LoginComponent;