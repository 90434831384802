import { Box, CloseButton, Flex, HStack, Progress, Spinner, Stack, Tag, TagLeftIcon, Text, Tooltip } from "@chakra-ui/react";
import { DataSourceIcon } from "./dataSourceIcons";
import { DataSource } from "../../models/dataModel";
import { BiPlusMedical } from "react-icons/bi";
import { DeleteWithConfirm } from "../DeleteWithConfirm/deleteWIthConfirm";
import { getApi } from "../../apiService";
import useSWR from 'swr'
import { Icon3dCubeSphere } from "@tabler/icons-react";
import Moment from "react-moment";
import { IndexBadge } from "../IndexBadge/indexBadge";


export function DataSourceBox({
    dataSource,
    size="medium",
    hoverEffects=true,
    active=true,
    onClick,
    onDelete
}:{
dataSource?:DataSource
size?:"small"|"medium"|"large"
hoverEffects?:boolean
active?:boolean,
onClick?:()=>any
onDelete?:()=>any
}) {
  
    function loadIndexStatus(){
        if (!dataSource.index_id) return
        return getApi().getIndex(dataSource.index_id).then((res)=>{
            return res.indexing_progress
        })
    }

    const {data:indexingProgress, isLoading:indexingProgressLoading} = useSWR(`index/`+dataSource.index_id,()=>loadIndexStatus())
 

  return (
    <HStack className="data-source-box"  align="center" opacity={!active?0.5:undefined} justify="stretch" flexGrow="1" border="1px solid transparent" borderRadius={5} p="5px" _hover={hoverEffects?{border:"1px solid gray" }:undefined} cursor="pointer" >
    <Stack direction="row" align="center" flexGrow="1 "  borderRadius={5} onClick={onClick}>
        <DataSourceIcon size={size} type={dataSource?.type}/>
        
        <Flex align="start" direction="row" justify="space-between" width="100%">
            <Text margin="0"  lineHeight="1em" fontWeight={900} fontSize="sm">{dataSource?.name||"* New DataSource"}</Text>
            
            {dataSource.index_id && <IndexBadge index_id={dataSource.index_id}/>}
        </Flex>
    </Stack>
    {onDelete && <DeleteWithConfirm  onClick={onDelete}/>}
    </HStack>
  );
}

export function AddDataSourceButton({
    size,
    datasourceTitle,
    type,
    description,
    onClick
}:{
    datasourceTitle?:string,
    description?:string,
    type?:string,
    size:"small"|"medium"|"large"
    onClick?:()=>any
}) {

    function getSize(size, ratio=1){
        if (size === "small") return 15*ratio+"px"
        else if (size === "medium") return 25*ratio+"px"
        else if (size === "large") return 35*ratio+"px"
        else if (size === "xlarge") return 50*ratio+"px"
        else return  25*ratio+"px"
    }

  
  return (
    <Stack className="DataSource-box" direction="row" opacity="0.5" align="center" _hover={{opacity:"0.9"}} onClick={onClick} cursor="pointer" p="0px 5px">
            <DataSourceIcon size={size} type={type}/>
                <BiPlusMedical color="gray" size={getSize(size,0.8)}/>

        <Flex align="start" direction="column" justify="center">
            <Text margin="0" lineHeight="1em" fontWeight={500} fontSize={size=="small"?"xs":size} color="black">Add a <b>{datasourceTitle}</b> data source </Text>
            <Text  margin="0" lineHeight="1em"  fontSize={"xs"}  color="gray.900">{description}</Text>
            
        </Flex>
        
    </Stack>
  );
}