import { Box, Flex, Spinner, Stack, Switch } from "@chakra-ui/react";
import { ImSearch } from "react-icons/im";
import { DataSource, MetaType, Skill } from "../../models/dataModel";
import { useEffect, useMemo, useState } from "react";
import { AutoUI } from "../AutoUI/AutoUI";
import { getApi } from "../../apiService";
import { AddDataSourceButton, DataSourceBox } from "./dataSourceBox";
import { EditModal } from "../ModalDialogs/editModal";
import { DataSourceConfigEdit } from "./dataSourceConfigEdit";
import useSWR from 'swr'
import { v4 as uuidv4 } from 'uuid';
import { DataSourceCaption } from "./dataSourceCaption";


export const DataSourcesBox = ({allDataSources,selectedDataSources,onSelectedDataSourcesChange,onAddDataSource,onEditDataSource,onDeleteDataSource,availableDataSourceTypes}:{
    allDataSources:DataSource[], 
    selectedDataSources?:string[],
    onSelectedDataSourcesChange?:(newSelectedDataSources:string[])=>any,
    onAddDataSource:(dataSource:DataSource)=>any,
    onEditDataSource:(i:number, dataSource:DataSource)=>any,
    onDeleteDataSource?:(dataSource:DataSource)=>any,
    availableDataSourceTypes?:string[]
}) => {


    const [value, _setValue] = useState<DataSource[]>()
    useEffect(()=>_setValue(allDataSources), [allDataSources])

    //const [editedDatasource, setEditedDatasource] = useState<DataSource|null>()
    const [modal, setModal] = useState<any>(null)
    const usedDataSourceTypes = useMemo(()=>
        value ?Array.from(new Set(value.map((ds)=>ds.type))):[]
    ,[value])
    
    const loadDatasourceTypes = ()=>{
      
      return   getApi().getMetaSchemaTypes("datasources").then((res)=>{
            
            return res
        })
      }


    const {data:allDataSourceTypes, isLoading} = useSWR(`datasources`,()=>loadDatasourceTypes())

    const _availableDataSourceTypes = useMemo(()=>
        availableDataSourceTypes?.length? allDataSourceTypes.filter((type)=>availableDataSourceTypes.includes(type.name)):allDataSourceTypes
    ,[allDataSourceTypes])

    


    function addNewDatasource(dataSource:DataSource) {
        if (dataSource)
            onAddDataSource(dataSource)
        else
            console.error("DataSource must be set!")
    }



    function showDataSourceDialog(type:string, dataSource=undefined){
        {
            let newDataSource:DataSource =dataSource
            if (!newDataSource){
                let len = allDataSources.filter(ds=>ds.type==type).length
                newDataSource = {uuid:uuidv4(), type:type}

            }

            const typeMeta = _availableDataSourceTypes.find((t)=>t.name==type)
            
            setModal(<EditModal 
                caption={(<DataSourceCaption type={type} text={dataSource?`Edit ${typeMeta.title} skill`:`Add new ${typeMeta.title} data source`} />)} 
                value={newDataSource}
                onOk={val=>{
                    if (dataSource){
                        onEditDataSource(value.indexOf(dataSource), val)
                    }else{
                        addNewDatasource(val)
                    }
                    setModal(undefined)

                    }} onCancel={()=>setModal(undefined)}>
                {(val, setVal)=>
                    <DataSourceConfigEdit 
                        value={val} 
                        
                        onChange={setVal}  />}
            </EditModal>)
        }
    }


 return(

     <Box>
        
         {modal}
        {isLoading?(<Spinner/>):(
            <Stack margin="10px 2px">
                
                
                {usedDataSourceTypes.map((type)=>(
                    <Stack key={type} margin="0px 0px">
                        {value?.filter((ds)=>ds.type==type).map((ds,i)=>(
                            <Flex key={i} direction="row" align="center">
                                

                            
                            <DataSourceBox dataSource={ds} active={(onSelectedDataSourcesChange )?selectedDataSources?.includes(ds.uuid):undefined} onClick={()=>showDataSourceDialog(ds.type, ds)}
                             onDelete={onDeleteDataSource?()=>onDeleteDataSource(ds):undefined}
                            />
                            {onSelectedDataSourcesChange && <Switch m="0px 10px" isChecked={!selectedDataSources || selectedDataSources?.includes(ds.uuid)} onChange={(e=> {
                                    if (e.target.checked){
                                        
                                        let newSelectedDataSources =  Array.from(new Set([...selectedDataSources, ds.uuid]))
                                        if (newSelectedDataSources.length===allDataSources.length){
                                            onSelectedDataSourcesChange(null)
                                        }
                                        else{
                                            onSelectedDataSourcesChange([...selectedDataSources, ds.uuid])
                                        }
                                    }
                                    else{
                                        if (!selectedDataSources) {
                                            onSelectedDataSourcesChange(allDataSources.filter((d)=>d.uuid!=ds.uuid).map((ds)=>ds.uuid))
                                        }
                                        else{
                                            onSelectedDataSourcesChange(selectedDataSources.filter((dsKey)=>dsKey!=ds.uuid))
                                        }
                                    }   
                                    })} 
                                />}
                            </Flex>
                        ))}
                        
                    </Stack>
                ))}
                {_availableDataSourceTypes?.filter(st=>!usedDataSourceTypes.includes(st.name)).map((type)=>(
                        <AddDataSourceButton key={type.name} size="medium" 
                            type={type.name} 
                            datasourceTitle={type.title} 
                            description={type.description}
                            onClick={()=>showDataSourceDialog(type.name)}
                           
                            />

                    ))}
            </Stack>
        )}
    </Box>
      )  
}
