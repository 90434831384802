
import { Input } from '@chakra-ui/react';
import  { useState, useRef, useEffect } from 'react';

export const AutosizeTextInput = ({value,className, onValueChange,minWidth=null,onApply,placeholder,   ...rest}:{
  value?:string, 
  minWidth?:number,
  className?:string,
  placeholder?:string,
  onValueChange?:(val:string)=>any,
  onApply?: (value)=>any,
  [key:string]:any

}) => {
  const [content, setContent] = useState(value||"");
  const [width, setWidth] = useState(minWidth||100);
  const span = useRef();

  useEffect(() => {
    if (span?.current){
      let _width=(span.current as any).offsetWidth
      if(_width){
        setWidth( Math.min(Math.max(minWidth|100, _width*1.2), document.body.clientWidth-100));
      }
        (span?.current as any).style.display = "none";
    }
  }, [content]);

  const changeHandler = evt => {
    setContent(evt.target.value);
    onValueChange && onValueChange(evt.target.value)
  };

  return (
    <div>
      <span style={{maxWidth:"100%"}} ref={span}>{value||placeholder}</span>
      
      
      <Input {...rest} className={className}  value={content}  p="2px" size="xs" fontSize="sm" placeholder={placeholder}
      type="text" style={{ width, maxWidth:"80vw", fontWeight:"500"}} autoFocus 
      rounded={4}
      onChange={changeHandler} 
      onBlur={()=>onApply(content)}
      onKeyDown={(e)=>{
        e.key === "Enter" && onApply && onApply(content)
      }}
       />
    </div>
  );
};