import { Box, Flex, Grid, GridItem, HStack, Heading, Text, Tooltip } from "@chakra-ui/react";
import { TopicInfo } from "../../models/dataModel";
import { SimpleChart } from "./simpleChart";
import { Badge } from '@chakra-ui/react'
import { IconMoodAnnoyed2 } from "@tabler/icons-react";
import { IconMoodHappy } from "@tabler/icons-react";
import { IconMoodUnamused } from "@tabler/icons-react";
import { IconMoodLookLeft } from "@tabler/icons-react";



const TopicBox = ({topic: topicData}:{
    topic:TopicInfo
}) => {

    return (<Grid
  templateAreas={`"header chart"
                  "info chart"`}
  gridTemplateRows={'2fr 1fr'}
  gridTemplateColumns={'2fr 1fr'}
  
  maxH="100px"
  minH="100px"
  flexGrow={0}
  
  w="450px"
  maxW="80vw"
  overflow="hidden"
  
  gap='1'
  color='blackAlpha.700'
  fontWeight='bold'
  borderWidth={1}
    background='white'
    
    borderRadius={10}
    
>
  <GridItem pl='2'  area={'header'} >
    <Flex justify="start" align="start" textAlign="start" p="10px 5px" width="115%">

    <Heading as="h3" size="sm" noOfLines={2}>{topicData?.topic.name}</Heading>
    </Flex>
  
  </GridItem>
  <GridItem pl='2' area={'info'} >
    <Flex align="end"   height="100%" p="6px 0px">
    {/* {topic.sentiment && (
        <HStack>
            <Tooltip label="Positive sentiment">
            <HStack color="green.600"><IconMoodHappy size="20px"/>
                <Text fontWeight={900} fontSize="15px">{topic.sentiment.positive*100} %</Text>
            </HStack>
            </Tooltip>
            <Tooltip label="Negative sentiment">
            <HStack color="red.600"><IconMoodAnnoyed2 size="20px"/>
                <Text fontWeight={900} fontSize="15px">{topic.sentiment.negative*100} %</Text>
            </HStack>
            </Tooltip>
            <Tooltip label="Neutral sentiment">
            <HStack color="cyan.600"><IconMoodLookLeft size="20px"/>
                <Text fontWeight={900} fontSize="15px">{topic.sentiment.neutral*100} %</Text>
            </HStack>
            </Tooltip>
        </HStack>
    )}
     */}
    </Flex>
  </GridItem>
  <GridItem pl='2'  area={'chart'}>
  <SimpleChart data={{}}/>
  </GridItem>
 
</Grid>)

}

export {TopicBox}