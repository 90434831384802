

import { Box, Button, Flex, HStack, IconButton, Text } from "@chakra-ui/react";
import { useState } from "react";
import { FiCopy } from "react-icons/fi";
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { a11yDark as style } from 'react-syntax-highlighter/dist/esm/styles/prism';

// import SyntaxHighlighter from 'react-syntax-highlighter';
// import { docco  as style  } from 'react-syntax-highlighter/dist/esm/styles/hljs'


export default function CodeBlock({ caption, children, language = "python", highlightLines, copyContent, ...rest }: {
    caption?: string
    children: any
    language?: string,
    highlightLines?: number[]
    copyContent?: string
    [key: string]: any
}) {

    const highlighter = (lineNumber, e) => {

        if (highlightLines.includes(lineNumber)) {
            let style = { display: 'block', backgroundColor: '#00491f' };
            return { style };
        }
        return {};
    }

    const [info, setInfo] = useState<string>()

    const copyButton = (
        <HStack align="center" spacing="5px" alignSelf="end">
            <Text textColor="white">{info}</Text>
            <IconButton size="xs" aria-label="Copy" p="4px" margin="4px 0px" icon={<FiCopy />} onClick={() => {
                setInfo("Copied!")
                navigator.clipboard.writeText(copyContent || children)
                setTimeout(() => setInfo(""), 1000)
            }
            } />
        </HStack>
    )

    return (
        <Box background="#2b2b2be5" borderRadius={5} p="0px 4px" {...rest}>
            {caption &&
                <Flex align="center" direction="row" justify="between">
                    <Box margin="2px 8px 0px">
                        <Text size="15px" fontWeight={900}>{caption}</Text>
                    </Box>
                    {copyButton}
                </Flex>}
            <Box  position="relative">
                {!caption && (
                    <Box position="absolute" top={0} right={0} opacity="50%">
                    {copyButton}
                    </Box>
                )}
                <SyntaxHighlighter language={language} style={style} customStyle={{ padding: "0px", margin: "0px", backgroundColor: "transparent", fontSize: "12px" }}

                    wrapLines={true}
                    showLineNumbers={true}
                    lineNumberStyle={(props) => {
                        return {
                            display: 'none',
                        };
                    }}
                    lineProps={highlightLines ? highlighter : undefined}
                >
                    {children}

                </SyntaxHighlighter>
            </Box>
        </Box>
    )

}