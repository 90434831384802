import React, { ReactNode, useEffect, useState } from 'react';

import {
    ChakraProvider,
  Box,
  Stack,
  HStack,
  Heading,
  Text,
  VStack,
  useColorModeValue,
  List,
  ListItem,
  ListIcon,
  Button,
  Link,
  Tooltip,
  Badge,
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useDisclosure,
  WrapItem,
  Wrap,
} from '@chakra-ui/react';
import { FaCheckCircle, FaPlusCircle } from 'react-icons/fa';
import { useAppContext } from '../../appContext';
import {ReactComponent as EnterpriseSvg} from "./enterprise.svg" 
import { getApi } from '../../apiService';




export default function Pricing({current,selected, onSelected}: {current?:string,selected?:string, onSelected?:any}) {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [selectedPlan, setSelectedPlan] = React.useState<string>()
    function signUp(plan:string){
        setSelectedPlan(plan)
        onOpen()
    }
    const {currentUser} = useAppContext()
    const [userInfo, setUserInfo] = useState()

    // useEffect(()=>{
    //     if (currentUser  ){
    //         getApi().getMyAccount().then((res)=>{
    //             setUserInfo(res)
    //         })
    //         .catch((err)=>console.error(err))

    //     }
    // },[currentUser])

  return (

    <ChakraProvider>

    <Modal isOpen={isOpen} onClose={onClose}  size="xl">
        <ModalOverlay />
        <ModalContent >
          <ModalHeader>Thank you for your interest 🙏</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack p="0px 0px 10px 10px" spacing={5}>
            <Text>
                We are currently in closed beta and we are onboarding new customers gradually, one by one to ensure the best possible experience.
            </Text>
            <Text>
                Please sign up for our waitlist and we will get back to you as soon as possible.
            </Text>
            <Box p="0px 40px 0px 5px">
                
            
            </Box>
            </Stack>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme='pink' variant="outline" onClick={onClose}>
              Close
            </Button>
            
          </ModalFooter>
        </ModalContent>
      </Modal>

    <Box py={12} px="20px">
    {!currentUser &&(
      <VStack spacing={2} textAlign="center">
        <Heading as="h1" fontSize="4xl">
       Plans that fit your need
        </Heading>
        <Text fontSize="lg" color={'gray.500'}>
          Start with 
          {" "}
            <Tooltip label="free trial is includes first 250 replies">
                <b>14-day free trial*  </b>
            </Tooltip>
            
          . No credit card needed. Cancel anytime.
        </Text>
      </VStack>)}
      <Stack
        direction={{ base: 'column', md: 'row' }}
        textAlign="center"
        justify="center"
        align="center"
        justifyItems="stretch"
        alignItems="stretch"
        
        flexWrap='wrap'
        //spacing={{ base: 4, lg: 10 }}
        py={10}>
        <PriceWrapper current={current=="starter"} selected={selected=="starter"} onClick={()=>onSelected("starter")}>
          <Box py={4} >
            <Text fontWeight="500" fontSize="2xl">
              Starter
            </Text>
            <HStack justifyContent="center">
              <Text fontSize="3xl" fontWeight="600">
                $
              </Text>
              <Text fontSize="5xl" fontWeight="900">
                49
              </Text>
              <Text fontSize="3xl" color="gray.500">
                /month
              </Text>
            </HStack>
          </Box>
          <VStack
            bg={useColorModeValue('gray.50', 'gray.700')}
            py={4}
            borderBottomRadius={'xl'}>
            <FeaturesList>
                <Replies max={500}/>
                <IndexedDocumentsFeature max={1000}/>
                <ListItem>
                    <ListIcon as={FaCheckCircle} color="green.500" />
                    Multilingual (100+ languages)
                </ListItem>
                <SkillsFeature max={3}/>
                <ListItem>
                    <ListIcon as={FaCheckCircle} color="green.500" />
                    Basic support plan
                </ListItem>


            </FeaturesList>
            {!onSelected && 
            <Box w="80%" pt={7}>
              <Button w="full" colorScheme="red" variant="outline" onClick={()=>signUp("Hobby")}>
                Start trial
              </Button>
            </Box>}
          </VStack>
        </PriceWrapper>

        <PriceWrapper current={current=="growth"} selected={selected=="growth"} onClick={()=>onSelected("growth")}>
          <Box position="relative">
            <Box
              position="absolute"
              top="-16px"
              left="50%"
              style={{ transform: 'translate(-50%)' }}>
              <Text
                textTransform="uppercase"
                bg={useColorModeValue('pink.300', 'pink.700')}
                px={3}
                py={1}
                color={useColorModeValue('gray.900', 'gray.300')}
                fontSize="sm"
                fontWeight="600"
                rounded="xl">
                Most Popular
              </Text>
            </Box>
            <Box py={4} >
              <Text fontWeight="500" fontSize="2xl">
                Growth
              </Text>
              <HStack justifyContent="center">
                <Text fontSize="3xl" fontWeight="600">
                  $
                </Text>
                <Text fontSize="5xl" fontWeight="900">
                  149
                </Text>
                <Text fontSize="3xl" color="gray.500">
                  /month
                </Text>
              </HStack>
            </Box>
            <VStack
              bg={useColorModeValue('gray.50', 'gray.700')}
              py={4}
              borderBottomRadius={'xl'}>
              <FeaturesList>
                <Replies max={1500}/>
                <IndexedDocumentsFeature max={5000}/>
                <ListItem>
                    <ListIcon as={FaCheckCircle} color="green.500" />
                    Multilingual (100+ languages)
                </ListItem>
                <SkillsFeature max={5}/>
                <ListItem>
                    <ListIcon as={FaCheckCircle} color="green.500" />
                    Basic support plan
                </ListItem>
              </FeaturesList>
              {!onSelected && 
              <Box w="80%" pt={7}>
                <Button w="full" colorScheme="pink"  onClick={()=>signUp("Growth")}>
                  Start trial
                </Button>
              </Box>}
            </VStack>
          </Box>
        </PriceWrapper>
        <PriceWrapper current={current=="scale"} selected={selected=="scale"} onClick={()=>onSelected("scale")}>
          <Box position="relative">
            <Box
              position="absolute"
              top="-16px"
              left="50%"
              style={{ transform: 'translate(-50%)' }}>
              <Text
                textTransform="uppercase"
                bg={useColorModeValue('blue.300', 'blue.700')}
                px={3}
                py={1}
                color={useColorModeValue('blue.900', 'blue.300')}
                fontSize="sm"
                fontWeight="600"
                rounded="xl">
                Best value
              </Text>
            </Box>
            <Box py={4} >
              <Text fontWeight="500" fontSize="2xl">
                Scale
              </Text>
              <HStack justifyContent="center">
                <Text fontSize="3xl" fontWeight="600">
                  $
                </Text>
                <Text fontSize="5xl" fontWeight="900">
                  349
                </Text>
                <Text fontSize="3xl" color="gray.500">
                  /month
                </Text>
              </HStack>
            </Box>
            </Box>
          <VStack
            bg={useColorModeValue('gray.50', 'gray.700')}
            py={4}
            borderBottomRadius={'xl'}>
            <FeaturesList>
            <Replies max={5000}/>
                <IndexedDocumentsFeature max={15000}/>
                <ListItem>
                    <ListIcon as={FaCheckCircle} color="green.500" />
                    Multilingual (100+ languages)
                </ListItem>
                <SkillsFeature max={undefined}/>
                <ProactiveNudging max={500}/>
                <ListItem>
                    <ListIcon as={FaPlusCircle} color="green.500" />
                    Conversations <b>analytics</b> + <b>A/B</b> testing
                </ListItem>
                <ListItem>
                    <ListIcon as={FaPlusCircle} color="green.500" />
                    Removable branding
                </ListItem>
                <ListItem>
                    <ListIcon as={FaPlusCircle} color="green.500" />
                    <b>Priority</b> support plan
                </ListItem>

              </FeaturesList>
            {!onSelected && 
            <Box w="80%" pt={7}>
              <Button w="full" colorScheme="blue" variant="outline"  onClick={()=>signUp("Scale")}>
                Start trial
              </Button>
            </Box>}
          </VStack>
        </PriceWrapper>
      </Stack>








        <PriceWrapper >
          <Box position="relative">
          
            <Box p={1} justifyContent="center">
              <VStack justifyContent="center" p="18px">
              <Text textAlign="center" fontWeight="500" fontSize="2xl">
                Enterprise
              </Text>
     
                <Text fontSize="2xl" color="gray.500">
                  contact us 
                </Text>
              </VStack>
            </Box>
            </Box>
          <VStack
            py={4}
            borderBottomRadius={'xl'}>
                <Wrap justify="space-between" width="100%">
            <FeaturesList height="auto" alignSelf="start" px="100px" flexGrow={1}>
                
                <ListItem>
                    <ListIcon as={FaCheckCircle} color="green.500" />
                    everything in scale plan
                </ListItem>
      
                <ListItem>
                    <ListIcon as={FaPlusCircle} color="green.500" />
                    Customer success manager
                </ListItem>
                <ListItem>
                    <ListIcon as={FaPlusCircle} color="green.500" />
                    Custom integrations
                </ListItem>
                <ListItem>
                    <ListIcon as={FaPlusCircle} color="green.500" />
                    <b>Priority</b> support plan
                </ListItem>
              </FeaturesList>
              <WrapItem width="350px" mx="100px" my="10px">

                <EnterpriseSvg/>
              </WrapItem>
              </Wrap>
            <Box  >
              <Button w="full" colorScheme="blue" variant="outline"  onClick={()=>signUp("Enterprise")}>
                Contact us
              </Button>
            </Box>
          </VStack>
        </PriceWrapper>
    </Box>
      </ChakraProvider>
    

  );
}


function PriceWrapper({ children, selected, current, onClick }: { children: ReactNode,
    selected?: boolean, 
    current?: boolean, 
    onClick?:()=>any
}) {
    return (
      <Flex
        mb={4}
        onClick={onClick ?()=>onClick():undefined}
        cursor={onClick?"pointer":undefined}
        direction="column"
        shadow="base"
        borderWidth={!selected?"1px":"3px"}
        minWidth="350px"
        //alignSelf={{ base: 'center', lg: 'flex-start' }}
        borderColor={selected||current?"twitter.600": 'gray.200'}
        
        
        borderRadius={'xl'}>
          <>
        {children}
        {(current || selected )&& <Box position="relative">
            <Box
              position="absolute"
              top="-16px"
              left="50%"
              style={{ transform: 'translate(-50%)' }}>
              <Text
                textTransform="uppercase"
                bg={selected?'blue.300': 'blue.100'}
                px={3}
                py={1}
                color='blue.900'
                fontSize="sm"
                fontWeight="600"
                rounded="xl">
                {current?"Current plan":"Selected"}
              </Text>
            </Box>
        </Box>}
        </>
      </Flex>
    );
  }
  
  function FeaturesList({ children, ...rest }: { children: ReactNode, [key:string]:any }) {
      return (
          <List spacing={3} textAlign="start" px={5} height="350px" {...rest}>
            
          {children}
        </List>
      );
    }
    
  
  
  
  const IndexedDocumentsFeature = ({max}:{max?:number}) => (
      <ListItem>
      <Tooltip label={
          <VStack>
  
          <p>Indexable document allow fast AI powered search and act like a long term memory for chatbot</p>
          <p>This includes products, pages, knowledge base documents etc.</p>
          </VStack>
      }>
          <Box>
      <ListIcon as={FaCheckCircle} color="green.500" />
  
      up to  {max? <>up to <b>{max}</b></>:<b>unlimited</b>}   indexed documents <Badge  variant="solid" transform="translateY(-8px)" p="0px 4px">?</Badge>
          </Box>
      
      </Tooltip>
    </ListItem>
  )
  
  const SkillsFeature = ({max}:{max?:number}) => (
      <ListItem>
      <Tooltip label={
          <VStack>
  
          <p>Advanced skills enhances chatbot capabilities beyond basic Question Answering. </p>
          <p>For example: Complex product search and recommendation, Proactive nudging with behavioural analysis etc, up-selling strategies, execute commands by calling external APIs etc...</p>
          </VStack>
      }>
          <Box>
      <ListIcon as={FaCheckCircle} color="green.500" />
  
      {max? <>up to <b>{max}</b></>:<b>unlimited</b>}  <b>advanced skills</b> <Badge  variant="solid" transform="translateY(-8px)" p="0px 4px">?</Badge>
          </Box>
      
      </Tooltip>
    </ListItem>
  )
  
  const ProactiveNudging = ({max}:{max?:number}) => (
      <ListItem>
      <Tooltip label={
          <VStack>
  
          <p>Actively analyses users behaviour and identifies high-intent visitors  </p>
          <p>Proactively approaches these users with a highly personalized messages</p>
          </VStack>
      }>
          <Box>
      <ListIcon as={FaCheckCircle} color="green.500" />
  
       <b>Proactive</b> sales <Badge  variant="solid" transform="translateY(-8px)" p="0px 4px">?</Badge>
       <Text marginLeft="25px" fontSize="sm">{max?<>(limited to  {max} sessions /mo)</>:"(unlimited)"} </Text>
          </Box>
      
      </Tooltip>
    </ListItem>
  )
  
  const Replies = ({max}:{max:number}) => (
      <ListItem>
      <Tooltip label={
          <VStack>
  
          <p>replies over the limit are priced 0,10$ per reply</p>
          </VStack>
      }>
          <Box>
      <ListIcon as={FaCheckCircle} color="green.500" />
  
      <b>{max}</b> replies included <Badge  variant="solid" transform="translateY(-8px)" p="0px 4px">?</Badge>
          </Box>
      
      </Tooltip>
    </ListItem>
  )