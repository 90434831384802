import { Badge, Box, Flex, Grid, GridItem, HStack, Heading, Stack, Text, Tooltip, Wrap, WrapItem } from "@chakra-ui/react";



const LimitItems = ({children, limitCount, renderOverflow, ...rest}:{
    children:any[]
    renderOverflow?:(overflowCount)=>any,
    limitCount:number
    [key:string]:any
}) => {
    const overflowCount =  children?.length-limitCount;
    return (<Wrap direction="row" {...rest}>
        {children.slice(0,limitCount).map((child,i)=>(
            <WrapItem key={i}>
                {child}
            </WrapItem>
        
        ))}
        
        {children.length>limitCount && <WrapItem>
            <Tooltip width="auto" maxW="70vw" maxH="60vh" label={<Stack p="5px">{children.slice(limitCount).map((child,i)=><Box key={i}>child</Box>)}</Stack> }>
            {(renderOverflow ? renderOverflow(overflowCount):(
                <Badge>+{overflowCount}</Badge>
                )
            )}
            </Tooltip>
        </WrapItem>
        }
    </Wrap>)

}

export {LimitItems}