
import Moment from "react-moment";
import { ConversationSession, TopicInfo } from "../../models/dataModel";
import { Stack, Box, Flex, Grid, GridItem, HStack, Heading, Text, Tooltip, Badge, Spinner, VStack } from "@chakra-ui/react";
import { SearchFilter } from "../SearchFilter/searchFilter";
import { useEffect, useState } from "react";
import useSWRInfinite from 'swr/infinite'
import { getApi } from "../../apiService";
import { IconHistoryToggle } from "@tabler/icons-react";
import { useParams } from "react-router-dom";
import { IconAlignBoxLeftTop } from "@tabler/icons-react";
import { Rating } from "../Rating/rating";
import { LimitItems } from "../LimitItems/limitItems";


const SessionBox = ({session, onClick, isActive}:{
    session:ConversationSession,
    onClick?:()=>any
    isActive?:boolean
})=>{
    return (
        <Grid
            templateAreas={`"header header"
                            "time visitor" `}
            gridTemplateRows={'auto auto'}
            gridTemplateColumns={'1fr 1fr'}
            
            
            w="450px"
            flexShrink={0}
            overflow="hidden"
            
            gap='1'
            color='blackAlpha.700'
            fontWeight='bold'
            borderWidth={1}
                borderColor={isActive?"brand.800":undefined}
                background='white'
                
                borderRadius={10}
                p="8px"
                onClick={onClick}
                cursor="pointer"
            >
            <GridItem area={'header'} >
                <Stack>
                    <Flex justify="start" align="start" textAlign="start">

                        {session?.summary ? (
                        <VStack align="start" spacing="2px">
                            <HStack>
                            <IconAlignBoxLeftTop/>
                            <Text fontWeight={900}  fontSize="sm">Summary</Text> 
                            </HStack>
                            <Text fontWeight={500}  fontSize="sm">{session?.summary}</Text> 
                        </VStack>
                        ): (<HStack color={isActive?"brand.800":"blackAlpha.500"}>
                            <IconHistoryToggle/>
                            <Text fontWeight={600}  fontSize="sm">Recent conversation</Text>
                            </HStack>
                            )
                        }
                    </Flex>
                    {session.entities && <LimitItems direction="column" limitCount={3}>
                        {session.entities.map((e,i)=>(
                            <Badge key={i}>{e}</Badge>
                        ))}
                    </LimitItems>}
                    {session.satisfaction_score >=0 &&  (<Stack align="start" spacing={0}>
                    <Text fontSize="2xs" fontWeight={500}>Estimated satisfaction</Text>
                    <Rating value={session.satisfaction_score}/>
                    </Stack>
                    )}
                </Stack>
            
    
    </GridItem>
  <GridItem area={'time'} >
    <Flex align="end"   height="100%"  >
        <Badge fontWeight={500} ><Moment fromNow>{session.start}</Moment></Badge>
    
    </Flex>
  </GridItem>
  <GridItem  area={'visitor'}>
    <Flex justify="end" align="end" height="100%" >
        <Badge fontWeight={500} >{session.visitor_id}</Badge>
    </Flex>
  </GridItem>
 
</Grid>
    )
}

  
interface ConversationSessionsFilter{
    dateRangeStart?:Date
    dateRangeEnd?:Date
}

const ConversationSessions = ({onSessionClicked, activeSessionId}:{
    activeSessionId?:string
    onSessionClicked:(session:ConversationSession)=>any,
    
    
}) => {

    
    const [query,setQuery] = useState("")
    const [filter,setFilter] = useState<ConversationSessionsFilter>()
    
    const {appId}=useParams()
    const PAGE_SIZE=25
    const { data:pages, size, setSize ,isLoading} = useSWRInfinite(
        (index) =>JSON.stringify([filter, query, index]),
        (_args)=>{
            let args = JSON.parse(_args)
            let filter=args[0]
            let query=args[1]
            let index=(args[2] ||0)
            return getApi().getSessions({
                appId:appId,
                q:query,
                dateRangeStart:filter?.dateRangeStart ? new Date(filter?.dateRangeStart).toISOString() : undefined,
                dateRangeEnd:filter?.dateRangeEnd ? new Date(filter?.dateRangeEnd).toISOString() : undefined,
                skip:index*PAGE_SIZE, 
                limit:PAGE_SIZE})
        },
        {
            revalidateIfStale:true,
            revalidateFirstPage:false
        }
      );

    useEffect(()=>{
        if (!pages?.find(p=>p.find(s=>s.id===activeSessionId))){
            if (pages){
                onSessionClicked(pages[0][0])
            }
            else{
                onSessionClicked(undefined)
            }
        }
    },[pages])

    return (
    <Flex flexGrow={1} flexBasis="400px">
        <Stack background="white.900" 
        height="100%" 
        width="100%"
        // bgGradient="linear(to-b, white, gray.50)"
        align="start"
        justify="start"
        spacing="10px"
        flexGrow={1}
        rounded="5px"
        
        >
           
            <Flex  height="100%" bgColor="white" borderWidth={1} rounded={15}  overflow="hidden" flexGrow={1} width="100%">
            <Stack height="100%"  p="10px" 
            overflow="auto"
            border="5px solid white"
            justify="start"
            
            maxH="calc(100vh - 125px)"
            flexGrow={1}
            >
                <Box position="sticky"  m="-5px -5px 0px" top="-15px" p="10px" bgColor="white">
                <SearchFilter 
                search={query}
                filter={{dateRange:{start:filter?.dateRangeStart,end:filter?.dateRangeEnd}}}
                onChange={(search, filter)=>{
                    setQuery(search)
                    setFilter({dateRangeStart:filter?.dateRange?.start,dateRangeEnd:filter?.dateRange?.end})
                }}
                
                filterDefinition={{
                    dateRange:{
                        label:"Date range",
                        type:"date",
                        options:{
                            "Today":[new Date(), new Date()],
                            "Yesterday":[new Date(), new Date()],
                            "Last week":[new Date(), new Date()],
                            "Last month":[new Date(), new Date()],
                        }
                    }
                }}/>
                </Box>
                {isLoading &&(
                    <Box justifySelf="center">
                        <Spinner size="xl"/>
                    </Box>
                )}
                {!pages?.length && !isLoading && 
                    <Box p="20px 0px">
                    {(!filter && !query )? (<Text color="gray.500" textAlign="center" fontSize="sm" >There seems to be no conversations yet</Text>):(
                        <Text color="gray.500" textAlign="center" fontSize="sm" >No results found</Text>
                    )}
                    </Box>
                    }
                {pages && pages.map((page_items,page_i) => (
                    page_items?.map((session, index) => (
                        <SessionBox key={session.id} session={session} isActive={activeSessionId===session.id}
                        onClick={()=>onSessionClicked(session)}
                        />
                        )
                    )
                    ))}
            {/* {fakeData.map((session)=>(
                <SessionBox key={session.id} session={session}
                onClick={()=>onSessionClicked(session)}
                />
            ))} */}
                
            </Stack>
            </Flex>
       
        </Stack>
    </Flex>
    )

}

export {ConversationSessions}



const fakeData=[
    {
      "id": "session001",
      "visitor_id": "visitor123",
      "start": "2023-05-03T09:00:00",
      "end": "2023-08-03T09:30:00",
      "summary": "Product inquiry",
      "entities": {
        "product": "Widget A",
        "interested": true,
        "rating": 4.5
      }
    },
    {
      "id": "session002",
      "visitor_id": "visitor456",
      "start": "2023-05-03T14:15:00",
      "end": "2023-08-03T15:00:00",
      "summary": "Support ticket Support ticket Support ticket Support ticket Support ticket Support ticketSupport ticketSupport ticketSupport ticketSupport ticketSupport ticket",
      "entities": {
        "ticket_id": "SUP4321",
        "issue": "Login problem",
        "status": "Open"
      }
    },
    {
      "id": "session003",
      "visitor_id": "visitor789",
      "start": "2023-06-03T10:30:00",
      "end": "2023-08-03T11:00:00",
      "summary": "Feedback",
      "entities": {
        "feedback": "The service was excellent!",
        "rating": 5,
        "suggestions": ["Improve response time", "Add more payment options"]
      }
    },
    {
      "id": "session004",
      "visitor_id": "visitor321",
      "start": "2023-08-03T16:30:00",
      "end": "2023-08-03T17:15:00",
      "summary": "Order inquiry",
      "entities": {
        "order_id": "ORD12345",
        "product": "Gadget B",
        "quantity": 2
      }
    },
    {
      "id": "session005",
      "visitor_id": "visitor654",
      "start": "2023-08-03T13:45:00",
      "end": "2023-08-03T14:00:00",
      "summary": "Demo request",
      "entities": {
        "product": "Software C",
        "requested_date": "2023-08-10",
        "contact_name": "John Doe"
      }
    },
    {
      "id": "session006",
      "visitor_id": "visitor987",
      "start": "2023-08-03T11:30:00",
      "end": "2023-08-03T12:15:00",
      "summary": "Complaint",
      "entities": {
        "complaint": "Damaged item received",
        "order_id": "ORD56789",
        "refund_requested": true
      }
    },
    {
      "id": "session007",
      "visitor_id": "visitor159",
      "start": "2023-08-03T09:30:00",
      "end": "2023-08-03T10:00:00",
      "summary": "Question about warranty",
      "entities": {
        "product": "Appliance D",
        "warranty_duration": "2 years",
        "purchased_date": "2023-06-15"
      }
    },
    {
      "id": "session008",
      "visitor_id": "visitor753",
      "start": "2023-08-03T15:30:00",
      "end": "2023-08-03T16:00:00",
      "summary": "Subscription renewal",
      "entities": {
        "subscription_id": "SUB9876",
        "plan": "Gold",
        "renewal_date": "2023-09-01"
      }
    },
    {
      "id": "session009",
      "visitor_id": "visitor258",
      "start": "2023-08-03T12:30:00",
      "end": "2023-08-03T12:45:00",
      "summary": "Bug report",
      "entities": {
        "bug_description": "Application crashes on startup",
        "operating_system": "Windows 11",
        "app_version": "v2.3.0"
      }
    },
    {
      "id": "session010",
      "visitor_id": "visitor852",
      "start": "2023-08-03T14:30:00",
      "end": "2023-08-03T15:30:00",
      "summary": "Sales consultation",
      "entities": {
        "product": "Service E",
        "preferred_package": "Platinum",
        "budget": "$5000"
      }
    }
  ]