// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCHINOc3Le4nK91Mjooq9ellL0msm1rvac",
  authDomain: "geniously-app.firebaseapp.com",
  projectId: "geniously-app",
  storageBucket: "geniously-app.appspot.com",
  messagingSenderId: "480142350584",
  appId: "1:480142350584:web:17b4c7909cb7c5ca16030d",
  measurementId: "G-71RRZWCVEW"
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
const analytics = getAnalytics(firebaseApp);

export {firebaseApp, analytics}