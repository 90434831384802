
interface StatusUpdateServiceOptions{
    onNotification: (message: StatusUpdate) => void
    authToken: string|(()=>Promise<string>)
}

export class NotificationService {
    
    private onNotification: (message: StatusUpdate) => void
    private authToken: string|(()=>Promise<string>)
    private wsEndpoint:string
    private restEndpoint:string
    private static instance?:NotificationService
    private socket: WebSocket;
    
    
    private reconnecting=false
    
    
    private isClosedOnPurpose: boolean = false
    ň
    private constructor(options:StatusUpdateServiceOptions) {
        this.wsEndpoint= (process.env.REACT_APP_API_URL?.replace("http","ws") || "ws://localhost:4080")+"/status_updates"
        this.restEndpoint= (process.env.REACT_APP_API_URL || "http://localhost:4080")
        
        
        
        this.authToken = options.authToken;
        this.onNotification = options.onNotification;
        this.connect()
       
    }

    /*
    * This method is used to create a new instance of ChatService
    * 
    * @param options: ChatServiceOptions
    * @param openConnection: boolean - if true, the connection will be opened
    * @param chatServiceName: string - the name of the chat service instance, useful if you want to have multiple instances
    */
    public static getInstance(options: StatusUpdateServiceOptions) {
        
        if (!NotificationService.instance){
            
            NotificationService.instance= new NotificationService(options);
        }else{
            // we compare ws endpoint and auth token to see if we need to create a new instance / reconnect
            if ( options.authToken !== NotificationService.instance.authToken ){
                NotificationService.instance.disconnect()
                NotificationService.instance= new NotificationService(options);
            }
            else{
                // we just update the callbacks
                if (options.onNotification!==NotificationService.instance.onNotification){
                    NotificationService.instance.onNotification = options.onNotification;
                }
            }
        }

        if (NotificationService.instance.socket.readyState !== WebSocket.CONNECTING 
            && NotificationService.instance.socket.readyState !== WebSocket.OPEN){
                NotificationService.instance.connect();
        }
        return NotificationService.instance;
    }


    async onSocketOpen(){
        // Perform authentication here if needed
        await this.authenticateUser();
        this.isClosedOnPurpose = false
        
    };

    private onSocketClose = () => {
        // Perform authentication here if needed
        if (!this.isClosedOnPurpose && !this.reconnecting){
            this.reconnectWithBackoff().catch((err)=>{
                console.error(err)
            })
        }
    };
    private onSocketError(error: any) {
        console.error('WebSocket error: ', error);
    }

    async  getAuthToken()
    {
        if (typeof this.authToken === "string"){
            return  this.authToken
        }
        else{
            return await this.authToken()
        }
    }

    public getStatus(): Promise<{
        notifications_count: number,
        running_count: number
    }> {
        return this.getAuthToken().then(authToken=>fetch(this.restEndpoint+"/status", {headers: {
            Authorization: `Bearer ${ authToken}`,
          } })
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Failed to fetch notification status history');
                }
                return response.json();
            }))
    }

    public getNotifications(): Promise<StatusUpdate[]> {
        return this.getAuthToken().then(authToken=>fetch(this.restEndpoint+"/status/notifications", {headers: {
            Authorization: `Bearer ${authToken}`,
          } })
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Failed to fetch notification status history');
                }
                return response.json();
            }))
    }

    public clearNotifications(): Promise<any> {
        return  this.getAuthToken().then(authToken=>fetch(this.restEndpoint+"/status/notifications/mark_seen", {
            method: 'POST', 
            headers: {
            Authorization: `Bearer ${authToken}`,
          } })
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Failed to fetch notification status history');
                }
                return response.json();
            }))
    }

    public getRunning(): Promise<StatusUpdate[]> {
        return  this.getAuthToken().then(authToken=>fetch(this.restEndpoint+"/status/running_processes", {headers: {
            Authorization: `Bearer ${authToken}`,
          } })
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Failed to fetch notification status history');
                }
                return response.json();
            }))
    }
    

    private reconnectWithBackoff(delay=10,maxIter=10, currentIter=1) {
        return new Promise<void>((resolve, reject) => {
        this.reconnecting=true
        setTimeout(() => {
            if (this.socket.readyState=== this.socket?.OPEN || this.isClosedOnPurpose) {
                this.reconnecting=false
                resolve();
                return;
            } 
            console.log('Attempting to reconnect...');
            
            this.connect();
            if (maxIter > 0) {
                this.reconnectWithBackoff(delay, maxIter-1, currentIter+1).then(resolve).catch(reject);
            }
            else{
                reject(new Error('Connection failed'));
            }
        }, delay*currentIter*100);
        });
    }


    public disconnect() {
        this.isClosedOnPurpose = true
        this.socket.close();
        
    }

    public connect() {
        if (this.socket) {
            this.socket.onopen    = null
            this.socket.onmessage = null
            this.socket.onclose   = null
            this.socket.onerror   = null
        }
        this.socket = new WebSocket(this.wsEndpoint);
        this.socket.onopen = ()=>this.onSocketOpen();
        this.socket.onmessage = (ev)=> this.onSocketMessage(ev);
        this.socket.onclose = (ev)=> this.onSocketClose();
        this.socket.onerror = (ev)=>this.onSocketError(ev);
        
      }

   

    
    private onSocketMessage = (event: MessageEvent) => {
        const message = JSON.parse(event.data.toString());
        // Handle received messages
        this.handleMessage(message);
    };

    private async authenticateUser() {
        const authToken = await this.getAuthToken()
        const authMsg = {                
                type: "auth",
                bearer: authToken,
            }
        if (this.socket.readyState === WebSocket.OPEN) {
            this.socket.send(JSON.stringify(authMsg));
        } else {
            // Handle connection not open error
            console.error('WebSocket connection is not open. ... trying to reconnect');
            this.reconnectWithBackoff().catch((err)=>{
                console.error(err)
            })
        }
    }

   
    private handleMessage(message: any) {
        // Handle received message logic here
        if (this.onNotification) {
            // Handle regular message
            this.onNotification(message);
        }
    }
}
