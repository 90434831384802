import { Text } from "@chakra-ui/react";
import { ConfirmModal } from "../ModalDialogs/confirmModal"
import CodeBlock from "../codeBlock/codeBlock"

const DeployDialog = ({appId, onClose}:{appId, onClose}) => {

    return (<ConfirmModal caption="Deploy chatbot on your site" onOk={onClose}>
        <Text>
            Simply copy and paste the following code snippet into the HTML code of your website.
        </Text>
        <CodeBlock highlightLines={[5,6]}
        copyContent={`  <script src="https://geniously.ai/sjs/bundle.js"
        onload="initializeGeniouslyChat('${appId}', /* {style: { 'bottom': '25px' } optional styling */})"></script>`}
        >
        {`    
     
        <!-- ... all the other HTML code of your page
         add this at the end of the body tag -->
        <script src="https://geniously.ai/js/bundle.js"
        onload="geniously.initialize('${appId}')"></script>
    </body>
    </head>
        `}
        </CodeBlock>
    </ConfirmModal>)
}


export default DeployDialog;