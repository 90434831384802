import { Box, Flex, Grid, GridItem, Heading } from "@chakra-ui/react";
import { TopicInfo } from "../../models/dataModel";

import ReactECharts from 'echarts-for-react'; 
import * as echarts from 'echarts';

const SimpleChart = ({data}:{
    data:{[date:string]:number}
}) => {

    return (<Box
 
    >
        <ReactECharts
     style={{
        height: '100%',
        width: '100%',
      }}
  option={{
    height: "90px",
    xAxis: [
        {
          type: 'category',
          boundaryGap: false,
          show: false, // Hide x-axis
          data: [
            "2023-08-03T00:00:00Z",
            "2023-08-10T00:00:00Z",
            "2023-08-17T00:00:00Z",
            "2023-08-24T00:00:00Z",
            "2023-08-31T00:00:00Z",
            "2023-09-07T00:00:00Z",
            "2023-09-14T00:00:00Z"
          ].map(x=>new Date(x))
        }
      ],
      yAxis: [
        {
            show: false, // Hide y-axis
          type: 'value'
        }
      ],
      tooltip: {
        show: false // Disable tooltip
      },
      legend: {
        show: false // Hide legend
      },
      grid: {
        show: false,
        left: 0,
        top: 10,
        right: 0,
        bottom: 0
      },
    series: [
        {
          name: 'Line 1',
          type: 'line',
          stack: 'Total',
          smooth: true,
          lineStyle: {
            width: 0
          },
          showSymbol: false,
          areaStyle: {
            opacity: 0.8,
            color: new echarts.graphic.LinearGradient(0, 0.8, 1, 0.5, [
              {
                offset: 0,
                color: 'rgba(246, 6, 100,0.0)'
              },
              {
                offset: 0.3,
                color: 'rgba(246, 6, 100,0.7)'
              },
              {
                offset: 0.5,
                color: 'rgba(246, 6, 100,1)'
              },
              {
                offset: 1,
                color: 'rgba(74, 0, 174, 1)'
              }
            ])
          },
          emphasis: {
            focus: 'series'
          },
          data: [140, 232, 101, 264, 90, 340, 250]
        },
    ]
  }}
  //notMerge={true}
  lazyUpdate={true}
  //theme={"theme_name"}
  //onChartReady={this.onChartReadyCallback}
  
  //opts={}
/>
</Box>
)

}

export {SimpleChart}