import { Box, Flex, Grid, GridItem, HStack, Heading, Text, Tooltip } from "@chakra-ui/react";
import { TopicInfo } from "../../models/dataModel";
import { IconStarFilled } from "@tabler/icons-react";
import { IconStar } from "@tabler/icons-react";
import { IconStarHalfFilled } from "@tabler/icons-react";




const Rating = ({size=15, value, onChange}:{
    value:number,
    size?:number,
    onChange?:(value:number)=>void
}) => {
const show_stars=[0,0,0,0,0]
    return (
        
        <HStack>
            {show_stars.map((s,i)=>(
                <Box key={i} onClick={onChange?()=>onChange(i): undefined} cursor={onChange?"pointer":undefined}>
                    {Math.round(value*10)/10>=(i+1)?<IconStarFilled size={size+"px"}/>:(
                        Math.round(value*10)/10>=(i+0.5)?<IconStarHalfFilled size={size+"px"} />:
                            <IconStar size={size+"px"} color="gray"/>
                            )}
                </Box>
            ))}
        </HStack>
        
    )

}

export {Rating}