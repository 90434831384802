import { Box, Button, ButtonGroup, CloseButton, Flex, IconButton, Progress, Spinner, Stack, Tag, TagLeftIcon, Text, Tooltip } from "@chakra-ui/react";

import { getApi } from "../../apiService";
import useSWR from 'swr'
import { Icon3dCubeSphere, IconSearch } from "@tabler/icons-react";
import Moment from "react-moment";
import { useState } from "react";
import { InfoModal } from "../ModalDialogs/infoModal";
import { IndexSearch } from "../IndexSearch/indexSearch";



const IndexBadge = ({index_id}) => {
    function loadIndexStatus(){
        if (!index_id) return
        return getApi().getIndex(index_id).then((res)=>{
            return res.indexing_progress
        })
    }

    function searchIndex(){
        setModal(<InfoModal caption="Search index" onClose={()=>setModal(undefined)}> 
           <IndexSearch index_id={index_id}/>
           

           
        </InfoModal>)
    }

    const {data:indexingProgress, isLoading:indexingProgressLoading} = useSWR(`index/`+index_id,()=>loadIndexStatus())
    const [modal, setModal] = useState<any>(null)
    return <Flex  align="start" direction="row" >
            {modal}
           
            {indexingProgressLoading && <Box width="50px"><Spinner size="xs"/></Box>}
            {indexingProgress && <Tooltip label={
                <Text>{indexingProgress.processed!=indexingProgress.total?(`Reindexing ... ${indexingProgress.processed}/${indexingProgress.total}`):(
                    
                    <><b>Last full re-index:  </b>{indexingProgress.last_indexed?<Moment fromNow>{indexingProgress.last_indexed}</Moment>:"n/a"}</>
                    
                )}</Text>
            }>
            
                
                <ButtonGroup size='2xs' isAttached variant='outline' colorScheme="cyan"  >
                    <Button leftIcon={<Icon3dCubeSphere size="15px"/>} p="1px 3px"
                    onClick={(e)=>{
                        searchIndex()
                        e.stopPropagation()
                    }}
                    >
                   
                        {indexingProgress.processed!=indexingProgress.total?(
                            <Box width="50px">
                                <Progress hasStripe value={64} colorScheme="cyan" borderRadius="5px" isAnimated  />
                            </Box>
                        ):(
                            
                               <Text fontSize="xs" fontWeight={500}>Semantic index ready</Text>
                            
                        )}
                    </Button>
                    {/* {dataSource?.type} */}
                    {indexingProgress.processed==indexingProgress.total && 
                        <IconButton p="3px 3px 3px 5px" aria-label='Search in index' icon={<IconSearch size="12px"/>} 
                        onClick={(e)=>{
                            searchIndex()
                            e.stopPropagation()
                        }}
                        />
                    }
                </ButtonGroup>
            </Tooltip>}
        </Flex>

}

export {IndexBadge}