import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Spinner, Stack, Text } from "@chakra-ui/react";
import { ImSearch } from "react-icons/im";
import { Command, DataSource, MetaType, Skill } from "../../models/dataModel";
import { useEffect, useMemo, useState } from "react";
import { AutoUI } from "../AutoUI/AutoUI";
import { getApi } from "../../apiService";

import { EditModal } from "../ModalDialogs/editModal";
import { CommandTypeBox } from "./commandTypeBox";





export const CommandEdit = ({value:providedValue, onChange}:{
    value:Command, 
    onChange:(value:Command)=>any
    
}) => {

    
    const [isLoading, setIsLoading] = useState(false)
    // const [availableCommandTypes, setAvailableCommandTypes] = useState<MetaType[]>()
    const [modal, setModal] = useState<any>(null)
    const [commandValue, _setCommandValue] = useState<any>(null)
        
    function setCommandValue(val){
        if (val && !val.name){
            val.name = commandSchema?.properties?.name.default    
        }
        _setCommandValue(val)
        onChange&&onChange(val)
    }

    const [commandSchema, setCommandSchema] = useState<JSONSchema>(null)

    
    // useEffect(()=>{
    //   if (!availableCommandTypes){
    //     getApi().getMetaSchemaTypes("commands").then((res)=>{
    //         setAvailableCommandTypes(res)
    //     })
    //   }

    // },[])

    useEffect(()=>{
        if (providedValue) {
            if (commandValue && JSON.stringify(commandValue)==JSON.stringify(providedValue)) return
            _setCommandValue({...providedValue})
        }
    },[providedValue])

    useEffect(()=>{
        if (!commandValue ) return
        if (commandValue?.type && !commandSchema){
            setIsLoading(true)

            getApi().getMetaSchema("commands", commandValue.type).then((res)=>{
                res.properties["type"]["readOnly"] = true  
                setCommandSchema(res)
                setIsLoading(false)
            })
        }

    },[commandValue])


    // function setCommandType(type){
    //     setEditedCommand({...editedCommand, configuration:{type}})
    //     setEditedCommand({type})
    // }

    //const editedCommandType = useMemo(()=> editedCommand && availableCommandTypes?.find((type)=>type.name==editedCommand.type), [editedCommand])
    const lockedCommandTypes = ["client_action"]
 return(

     <Box >
         {modal}
        {isLoading?(<Spinner/>):(
            <Stack>
            
           
                {commandValue  && commandSchema &&(
                    <Box>

                    <AutoUI schema={commandSchema} value={commandValue} onValueChange={setCommandValue} excludeFields={["type"]}/>
                    </Box>
                )}
                

            </Stack>
            
        )}
    </Box>
      )  
}

