import { useEffect, useRef, useState } from "react";

import { Badge, Box, Button, Card, CardBody, CardFooter, CardHeader, ChakraProvider, Divider, Flex, HStack, Heading, IconButton, Image, SimpleGrid, Spinner, Switch, Text, Tooltip, VStack, Wrap, WrapItem } from "@chakra-ui/react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import "./homePage.css"
import { Logo } from "../components/CommonIcons/commonIcons";
import { IconX } from "@tabler/icons-react";
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
  } from '@chakra-ui/react'

const ScribbleSvg=()=><svg height="100%"  version="1.1" viewBox="0 0 80.9945 47.3231" width="100%" >

<defs/>
<g id="Layer-1" >
<g opacity="1">
<path d="M1.27669 11.5363C1.39451 11.376 14.2052 2.74232 21.7523 1.95798C30.2074 1.07927 37.0892 2.64928 40.7097 8.0172C45.8997 15.7122 38.1827 18.5831 32.4275 20.1403C26.6724 21.6975 14.9726 23.48 16.7736 17.4904C18.5746 11.5008 40.0989 13.4612 44.0396 15.2893C47.9803 17.1173 64.5888 27.1702 73.1056 43.7716" fill="none" fill-rule="evenodd" opacity="1" stroke="currentColor" stroke-linecap="butt" stroke-linejoin="miter" stroke-width="2.13508"/>
<path d="M74.0049 25.3415C74.0049 25.3415 70.5659 34.2712 73.6063 42.366C76.6467 50.4607 67.4358 34.265 58.1698 35.7836" fill="none" fillRule="evenodd" opacity="1" stroke="currentColor" strokeLinecap="butt" strokeLinejoin="miter" strokeWidth="2.13508"/>
</g>
</g>
</svg>

const InfoModal = ({caption, text, onClose}:{
    caption:string,
    text:string,
    onClose:()=>any
})=>{
return (
    <Modal isOpen={true} onClose={onClose}>
    <ModalOverlay />
    <ModalContent>
      <ModalHeader>{caption}</ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <Text>{text}</Text>
      </ModalBody>

      <ModalFooter>
        <Button colorScheme='blue' mr={3} onClick={(e)=>{
            e.preventDefault()
            onClose()
            }}>
          OK
        </Button>
        
      </ModalFooter>
    </ModalContent>
  </Modal>
)
}

export default function ShowCasePage() {

    

    const navigate = useNavigate()
    const pathParams = useParams()
    const [gChatClicked, setGChatClicked] = useState(true)
    const iframeRef = useRef<HTMLIFrameElement>(null)
    const [modal, setModal] = useState<any>(null)

    function handleClickAction({url=undefined, selector}){
        if (getUrl(url)!==iframeRef.current.src){
            iframeRef.current.src=getUrl(url)
            setModal(<InfoModal caption={"Click on button"} text="As Geniously chatbot is not directly integrated into this page, the button click cannot be executed in a fully automated manner. On a live webpage, the button would operate as intended." onClose={()=>setModal(undefined)}/>)
        }
    }

    function handleNavigateAction({url}){
        if (url!==iframeRef.current.src){
            iframeRef.current.src=getUrl(url)
            
        }
    }
    useEffect(() => {
        // Create a script element
        const script = document.createElement('script');
        script.src = 'https://geniously.ai/js/bundle.js';
        script.type = 'text/javascript';
        script.onload = () => {
            if (!document.getElementById('geniously-chat')) {
                setTimeout(()=>{
                    if ((window as any).geniously.initialize){
                        
                        console.log(".geniously.initialize - ok");
                        (window as any).geniously.initialize('fa320110-fee6-4351-bf4e-7b871388ee64',  {style: { 'bottom': '25px' },apiUrl:'http://localhost:4300' });
                        (window as any).geniously.setActionHandler("click", handleClickAction);
                        (window as any).geniously.setActionHandler("navigate", handleNavigateAction);
                        setGChatClicked(false)
                        setTimeout(()=>{
                            let gChat = document.getElementById('geniously-chat')
                            gChat.onclick=()=>{
                                setGChatClicked(true)
                            }
                        },1000)
                    }
                    },1000)
            }
        }
    
        // Append the script element to the head of the document
        document.head.appendChild(script);
        
        // Cleanup function to remove the script when the component unmounts
        return () => {
          document.head.removeChild(script);
        };
      }, []); // The empty dependency array ensures the effect runs only once
    
    const USE_PROXY = true;
    function getUrl(originalUrl){
        if (USE_PROXY){
            const urlObj = new URL("http://localhost:4300/extra/proxy")
            urlObj.searchParams.set("url", originalUrl)
            return urlObj.toString()
        }
        return originalUrl
    }

    return (
        <Box  className="general-page" height="100vh" width="100vw">
            {pathParams?.appId?(
                <Box>
                    {modal}
                    <Flex m="5px" justify="space-between">
                        <Logo className="logo-icon" size={"large"} withText/>
                        <Button onClick={()=>navigate("/home")} variant="ghost" size="sm"></Button>
                        <Text fontWeight={900} fontSize="xl" m="10px">Showcase</Text>
                    </Flex>
                        
                    <Card p="5px" margin={["5px","15px"]} borderWidth={1} shadow="2xl" height="calc(100vh - 90px)" overflow="hidden" background="gray.50" borderRadius="10px">
                        <Tooltip label="This is demo showcase of Geniously chatbot on your page">
                    <Flex bgGradient="linear(to-b,gray.200, gray.50)" height="45px" m="-5px" width="110%" p="5px">
                        <Box p="5px" borderRadius="full" backgroundColor="tomato" height="0px" m="10px 4px"></Box>
                        <Box p="5px" borderRadius="full" backgroundColor="orange" height="0px" m="10px 4px" ></Box>
                        <Box p="5px" borderRadius="full" backgroundColor="green" height="0px" m="10px 4px"></Box>
                        <HStack p="0px 10px" m="2px 10px" border="1px solid gray" borderRadius="5px" height="110%" backgroundColor="white">
                            <Text fontWeight={900} fontSize="xs">Oral B showcase with Geniously chatbot</Text>
                            <IconX size="10px" />
                        </HStack>
                        
                    </Flex>
                        </Tooltip>
                    <Box backgroundColor="white" height="100%" width="100%">

                    <iframe ref={iframeRef} src={getUrl("https://shop.oralb.co.uk/")} height="100%" width="100%"/>
                    </Box>
                    </Card>
                    {!gChatClicked && <Box color="#ff00c8" position="absolute" bottom="140px" right="50px" height="180px" css={{"$ mark":{background:"red"}}} >
                        
                        <Badge background="#ff00c8" transform="rotate(-10deg)"  fontSize="2xl" m="0px 0px 10px"  >Try it out!</Badge>
                        <ScribbleSvg/>
                    </Box>}
                    </Box>
            ):(
                <Box>
                    <Heading>App not found</Heading>
                </Box>
            )}
            
           
        </Box>
    )
}
