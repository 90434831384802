
import { useEffect, useMemo, useState } from "react";
import { getApi } from "../../apiService";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useAppContext } from "../../appContext";
import { AutoUI } from "../AutoUI/AutoUI";
import { Box, Button, Flex, Heading, Stack, Step, StepDescription, StepIcon, StepIndicator, StepNumber, StepSeparator, StepStatus, StepTitle, Stepper, Text, useSteps, useToast } from "@chakra-ui/react";
import { AddSkillButton, SkillBox } from "../SkillBox/skillBox";
import { AppProject, MetaType, Skill } from "../../models/dataModel";
import { SkillEdit } from "../SkillEdit/skillEdit";
import { EditModal } from "../ModalDialogs/editModal";
import { IconArrowBack, IconChevronLeft, IconChevronRight, IconCircleCheckFilled } from "@tabler/icons-react";

export default function AppSkillsEdit({appValue, onAppChange}:{
    appValue:AppProject,
    onAppChange:(app:AppProject)=>void

}) {

    
    
    const [modal, setModal] = useState<any>(null)
    const navigate = useNavigate()
    



    const [skillTypes,setSkillTypes] = useState<MetaType[]>([])
    useEffect(()=>{
        getApi().getMetaSchemaTypes("skills").then((res)=>{
            setSkillTypes(res)
        })
    },[])


    

    const usedSkillTypes = useMemo(()=>{
        if (!skillTypes || !appValue) return []
        
        return  skillTypes.filter((s)=>appValue.skills.find((sk)=>sk.type==s.name)).map((s)=>s.name)
    },[skillTypes, appValue])


    
    
  


    function showSkillDialog(type:string, skill=undefined){
        {
            const newSkill:Skill =skill||{type:type, configuration:{}}
            const typeMeta = skillTypes.find((t)=>t.name==type)
            
            setModal(<EditModal 
                caption={(<SkillBox size="small" hoverEffects={false} skill={{type, 
                        name:skill?`Edit ${typeMeta.title} skill`:`Add new ${typeMeta.title} skill`,
                        description:typeMeta.description,
                        configuration:null
                    }} />)} 
                // we need to pass along also datasources, because they can be edited in the skill edit dialog
                value={{skill:newSkill, data_sources:appValue.data_sources}}
                onOk={val=>{
                    if (!skill){
                        // dialog started without skill, just its type
                        onAppChange({ ...appValue, 
                            skills: [...appValue.skills, val.skill],
                            data_sources:val.data_sources
                         })

                    }
                    else{
                        onAppChange({...appValue, 
                            skills:[...appValue.skills.filter(s=>s!=skill), val.skill], // replace old skill with new one
                            data_sources:val.data_sources
                        })
                    }
                    setModal(undefined)

                    }} onCancel={()=>setModal(undefined)}>
                {(val, setVal)=>
                    <SkillEdit 
                        skill={val.skill} 
                        onChange={changedSkill =>setVal({skill:changedSkill, data_sources:val.data_sources})}
                        dataSources={val.data_sources}
                        onDataSourcesChange={(dataSources)=>{
                            setVal({...val, data_sources:dataSources})
                            
                        }}
                        />}
            </EditModal>)
        }
    }

    return (
        <Flex direction="column" align="stretch" flex={2}>
        
        <Stack align="start" margin={["5px","20px"]} alignSelf="stretch"  >
        {modal}


 
            <Box alignItems="start"  p="5px" width="100%">
            {/* <>{JSON.stringify({
                appValue: appValue,
                usedSkillTypes: usedSkillTypes,
                skillTypes: skillTypes
                
            },null,2)}
            </> */}
                <Text fontWeight={900}  fontSize="15px" margin="0px" textAlign="start">Skills</Text>
                <Text fontWeight={500} lineHeight="1em"  color="gray.600" fontSize="12px"  margin="0px" textAlign="start" >Skill are additional capabilities beyond the general knowledge you AI Chat bot has.</Text>
                <Text fontWeight={500} lineHeight="1em"  color="gray.600" fontSize="12px"  margin="0px" textAlign="start" >They have similar function as chatGPT plugins. They extend you chat bot capabilities for your specific use case</Text>
                <Stack margin="10px 2px">
                
                    {/* <SkillBox skill={{name:"a skill", type:"navigate"}}/>
                    <SkillBox skill={{name:"a skill", type:"command"}}/>
                    <SkillBox skill={{name:"a skill", type:"support_agent"}}/> */}
                    {usedSkillTypes.map((type)=>(
                        <Stack key={type} margin="10px 2px">
                            {appValue.skills.filter((skill)=>skill.type==type).map((skill, i)=>(
                                <SkillBox key={i} skill={skill} onClick={()=>showSkillDialog(skill.type,skill)} onDelete={()=>onAppChange({...appValue,skills:appValue.skills.filter(s=>s!=skill) }) }/>
                            ))}
                            <AddSkillButton size="small" type={type} text={`Add another ${type} skill`} onClick={()=>showSkillDialog(type)}/>
                        </Stack>
                    ))}

                    {skillTypes.filter(st=>!usedSkillTypes.includes(st.name)).map((type)=>(
                        <AddSkillButton key={type.name} size="medium" 
                            type={type.name} 
                            text={(<Text>Add a <b>{type.title}</b> skill ({type.name})</Text>)}
                            description={type.description}
                            onClick={()=>showSkillDialog(type.name)}/>

                    ))}
                    <AddSkillButton type="support_agent" text={<Text>Add a <b>Customer support</b> skill</Text>}  description="Transfer communication to a customer support if situation requires it" locked/> 
                    <AddSkillButton type="navigate" text={<Text>Add a <b>Website navigation</b> skill</Text>} description="Navigate user to the desired page/view" locked/> 
                    <AddSkillButton type="flow" text={<Text>Add a <b>Custom flow</b> skill</Text>} description="Design you own skill by providing a simple flow recipe" locked/> 
                    
                </Stack>
            </Box>
            
          
        </Stack>
       
        </Flex>
    )
}


