import React, { useEffect, useMemo, useState } from "react";
import { getApi } from "../apiService";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useAppContext } from "../appContext";
import { AutoUI } from "../components/AutoUI/AutoUI";
import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Badge, Box, Button, Flex, Grid, GridItem, HStack, Heading, Input, Portal, Spinner, Stack, Step, StepDescription, StepIcon, StepIndicator, StepNumber, StepSeparator, StepStatus, StepTitle, Stepper, TabIndicator, Text, Tooltip, VStack, Wrap, WrapItem, useSteps, useToast } from "@chakra-ui/react";
import { AddSkillButton, SkillBox } from "../components/SkillBox/skillBox";
import { AppProject, MetaType, Skill } from "../models/dataModel";

import { EditModal } from "../components/ModalDialogs/editModal";
import { IconArrowBack, IconChevronLeft, IconChevronRight, IconCircleCheckFilled, IconMessageCircle, IconPalette, IconRocket } from "@tabler/icons-react";
import AppSkillsEdit from "../components/AppDetail/appSkillsEdit";

import { DataSourcesBox } from "../components/DataSourcesEdit/dataSourcesSection";
// import ChatFloatingButton from "../chat/chatFloatingButton";
// import { ChatComponent } from "../chat/chatComponent";

import ColorPicker from "../components/ColorPicker/colorPicker";


import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react'
import { IconBook2 } from "@tabler/icons-react";
import { IconWand } from "@tabler/icons-react";
import { IconAdjustmentsHorizontal } from "@tabler/icons-react";
import { ChatComponent, ChatFloatingButton, GeniouslyThemeProvider, ChatContainer } from "geniously-chat-ui";

import DeployDialog from "../components/DeployDialog/deployDialog";

require('react-dom');
(window as any).React2 = require('react');
console.log("test: ",(window as any).React1 === (window as any).React2);

export default function AppEditPage() {
    
    
    const { currentUser, logOut, setCurrentAppProject, setIsAppModified, setInfo, setError } = useAppContext()
    const [modal, setModal] = useState<any>(null)
    const navigate = useNavigate()
    const [searchParams, setSearchParams] = useSearchParams()
    const [appValue, setAppValue] = useState<AppProject>()

    function updateAppValue(updateFunc: (appValue:AppProject)=>void){
        const newAppValue= {...appValue}
        updateFunc(newAppValue)
        setAppValue(newAppValue)
        setCurrentAppProject(newAppValue)
        if (JSON.stringify(newAppValue)!=JSON.stringify(savedValue)){
            setIsAppModified(true)
        }
    }
    const [savedValue, setSavedValue] = useState<AppProject>()
    const isChanged=useMemo(()=>JSON.stringify(appValue)!==JSON.stringify(savedValue),[appValue, savedValue])

    const { appId } = useParams()
    

    useEffect(()=>{
        if (!appId) return
        getApi().getApp(appId).then((res)=>{
            setSavedValue(res)
            setCurrentAppProject(res)
            setAppValue(res)
            setIsAppModified(false)
        })
    },[appId])



 
    const [saving, setSaving] = useState(false)
    const toast = useToast()
    function save(appValue:AppProject){
        setSaving(true)
        getApi().saveApp(appValue).then((res)=>{
            toast({title:"Success", description:"App successfully saved 🎉", status:"success", duration:2000, isClosable:true})
            setAppValue(res)
            setCurrentAppProject(res)
            setIsAppModified(false)
            setSavedValue(res)
        }).catch((err)=>{
            console.error(err)
            let details =  err.response?.data?.detail?.map(errDetail=>{
                let sliced = null
                if (errDetail?.loc?.length>1){
                    // skip first element, it's always "body"
                    sliced = errDetail?.loc.slice(1)
                    return sliced.join(".") + ": " +errDetail?.msg
                }
                else{
                    return null
                }
            }).filter((s)=>s)

            if (details?.length){
                // slice only first 3 errors
                details = details.slice(0,3)
            }
                
            toast({title:"Error ", description:details?details.join("\n"):err.message, status:"error", duration:5000, isClosable:true})
            
        }).finally(()=>{
            setSaving(false)
        })
    }

    
    const [showChat, setShowChat] = useState(false)


    
   function renderChat(active?:boolean){
    if (!active) 
        return  <Text>Please save your changes first</Text>
    else {
        let apiUrl=undefined;
        // if (process.env.NODE_ENV === 'development') {
        //     apiUrl='http://localhost:4300'
            
        // }
        // return <Box css={`
        //     & .geniously-chatFloatingButton {
        //         bottom:60px;
        //         right:60px;
        //     }
        // `}>

        //     <GeniouslyChat appId={appId} apiUrl={apiUrl} settings={{
        //         defaultOpen:true,
        //         debug:true,
        //     } }/>
        //   </Box>
        let wssUrl = process.env.REACT_APP_API_URL;
        wssUrl = wssUrl.replace("http","ws")
        const test_visitor_id = "test_visitor_id"
        return (
            // <ChatThemeContext.Provider value={createThemeColorScheme({ 
            //     colors:{
            //         primary:primaryColor,
            //         secondary:"#BE5A83"
            //     },
            //     mode:"light"
            // })}>
            
            
            <Flex direction="column" flexGrow={1} height="100%" >
            <GeniouslyThemeProvider theme={{ colors: { "primary": appValue.branding?.primary_color, "secondary": appValue.branding?.secondary_color } }}>
                <Flex borderWidth={1}  flexGrow={1} borderColor="blackAlpha.400" rounded={10} overflow="hidden" shadow="lg" id="g-test">

                    <ChatComponent wsEndpoint={`${wssUrl}/${appId}/chat`} visitorId={test_visitor_id}/>
                </Flex>
            
                <Flex justify="space-between" mt="5px" align="end">
                    <Button size="xs" variant="outline" colorScheme="brand" onClick={()=>{
                        fetch(`${process.env.REACT_APP_API_URL}/${appId}/chat/privacy/delete-history`,{
                        method:"POST",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body:JSON.stringify({
                            visitor_id:test_visitor_id
                        })
                    }).then((res)=>res.json()).then((data)=>{
                        setShowChat(false)
                        setTimeout(()=>{
                            setShowChat(true)
                        },100)
                        })
                        .catch((e)=>{
                            setError("Something went wrong. Please try again later.", "Clear conversation history")
                        })
                    }

                    }>Clear conversation history</Button>
                    {/* <Box p="30px 0px 0px" css="& * {cursor: not-allowed !important}">

                    <ChatFloatingButton iconType="default" position="relative" />
                    </Box> */}
                </Flex>
            </GeniouslyThemeProvider>
            </Flex>
            
        )
    }
   }

   
    
    const NiceTab =  React.forwardRef(({icon, children, number}:{
        icon?:any,
        children:string,
        number?:number
    }, ref) =>  {
        return (<Tab flexShrink={1}  flexBasis={"40px"} p={["1px","5px","10px"]}>
            <HStack spacing={2} fontWeight={900} >
                <Box flexShrink={1}>{icon}</Box>
                <Box flexShrink={1} flexGrow={1} >
                <Text  width="100%" fontSize={["8px","12px","14px"]} whiteSpace={"nowrap"} textOverflow="ellipsis" overflow="hidden" >{children}</Text>
                </Box>
                <Box flexShrink={1}>{number>0 && <Badge p="0px 2px" mb="5px" variant='solid' lineHeight="15px" alignSelf="start" colorScheme="brand"  color="white">{number}</Badge>}</Box>
            </HStack>
            </Tab>)
    })
    // function groupByTypeCount(objectList){
    //     const typeCountMap = {};
      
    //     objectList.forEach(({ type }) => {
    //       typeCountMap[type] = (typeCountMap[type] || 0) + 1;
    //     });
      
    //     return Object.entries(typeCountMap).map(([type, count]) => ({ type, count })) as { type: string; count: number }[];
    //   }

    if (!appValue )     {
        return( <Box p="20px"><Spinner size="xl"/></Box>)
    }
    
    
    return (
        <Stack >
             <HStack spacing={5} p="15px 20px" alignSelf="stretch" justifyContent="space-between"  position="sticky" top="0px" bgColor="gray.50" backdropBlur="3xl" zIndex={1}>
                    {appId?(
                        <Heading  fontSize="18px">{appValue?.chatbot_name}</Heading>
                ):( <Heading>Create new AI chat for your app</Heading>)}
                <HStack>
                 <Button colorScheme="brand" alignSelf="flex-start"    
                    //isLoading={saving}
                    isDisabled={!!!appId}
                    rightIcon={<IconRocket/>} onClick={()=>{
                        setModal(<DeployDialog appId={appId} onClose={()=>setModal(undefined)}/>)
                    }
                }>Deploy</Button>
                <Tooltip label={!isChanged?"No changes to save":undefined}>                
                <Button colorScheme="brand" alignSelf="flex-start"    
                    isLoading={saving}
                    isDisabled={!isChanged}
                    rightIcon={<IconCircleCheckFilled/>} onClick={()=>{
                        save(appValue)
                    }
                }>Save</Button>
                </Tooltip>
                </HStack>
            </HStack>
            <Stack align="start" p={["0px 15px","0px 20px"]} alignSelf="stretch" spacing={2}>
            {modal}
            
           
       
       <Box bgColor="white" rounded={10} borderWidth={1} shadow="md" p="5px" width="100%" >
       <Tabs colorScheme="brand" onChange={(tabIndex)=>setShowChat(tabIndex===4)} width="100%" >
        <TabList width="100%" overflow="hidden">
            <NiceTab icon={<IconAdjustmentsHorizontal/>}>General settings</NiceTab>
            <NiceTab icon={<IconBook2/>} number={appValue?.data_sources?.length}> Knowledge base</NiceTab>
            <NiceTab icon={<IconWand/> } number={appValue?.skills?.length}>Skills</NiceTab>
            <NiceTab icon={<IconPalette/> } >Branding</NiceTab>

            <NiceTab icon={<IconMessageCircle/> } >Test your bot</NiceTab>
        </TabList>
        <TabIndicator
      mt="-1.5px"
      height="3px"
        bgColor="brand.500"
      
    />
        <TabPanels >
            <TabPanel overflow="auto" maxH="calc(100vh - 205px)">
                <AutoUI 
                    value={{name:appValue.name, instructions:appValue.instructions,chatbot_name:appValue.chatbot_name, default_languages: appValue.default_languages}}
                    onValueChange={val=>setAppValue({...appValue, name:val.name, instructions:val.instructions, chatbot_name:val.chatbot_name, default_languages:val.default_languages})}
                    schema={
                        {
                            "title": "DataSourceDefinition",
                            "type": "object",
                            "properties": {
                                "name": {
                                    "title": "Project name",
                                    "description": "Name of this AI app",
                                    "maxLength": 256,
                                    "example": "",
                                    "type": "string"
                                },
                            "chatbot_name": {
                                "title": "Chatbot name",
                                "description": "Name used in communication with the user",
                                "maxLength": 256,
                                "example": "ChatBot, Siri or HAL 9000",
                                "type": "string"
                            },
                            "instructions": {
                                "title": "Instructions",
                                "description": "Describe how the bot should behave",
                                "maxLength": 1024,
                                "example": "You are an support agent for MyApp.\nYou can help our users with questions on how to use our app.\nYou also can take orders and report bugs.\nIf asked to help with task unrelated to MyApp, say that you can help with anything regarding to MyApp but and give a general recommendation to contact someone else.",
                                "type": "string"
                            },
                            "default_languages": {
                                "title": "Default languages",
                                "description": "Optionally you can define the default language your chatbot should use. Otherwise it would be detected automatically.\nNote that we will always try to detect the language, but providing us the list of most likely languages will help to improve the accuracy of the detection.",
                                "maxLength": 256,
                                "example": "English, German, French, ...",
                                "type": "string"
                            },
                            },
                            "required": [
                            "name",
                            //"instructions",
                            ],
                            
                        }
                        
                    }

                    
                    />
               
            </TabPanel>
            <TabPanel  overflow="auto" maxH="calc(100vh - 205px)">
            <DataSourcesBox
                allDataSources={appValue.data_sources}
                onEditDataSource={(i,ds)=>{ 
                    const newDataSources = [...appValue.data_sources]
                    newDataSources[i]=ds
                    setAppValue({...appValue, data_sources:newDataSources})
                }}
                onAddDataSource={(ds)=>{
                    setAppValue({...appValue, data_sources:[...appValue.data_sources, ds]})
                }}
                onDeleteDataSource={(ds)=>{
                    setAppValue({...appValue, data_sources:appValue.data_sources.filter((d)=>!(d===ds || (d.uuid && d.uuid===ds.uuid)))})
                }}
                />
            </TabPanel>
            <TabPanel  overflow="auto" maxH="calc(100vh - 205px)">
                  <AppSkillsEdit appValue={appValue} onAppChange={setAppValue}/>
            </TabPanel>
            <TabPanel  overflow="auto" maxH="calc(100vh - 205px)">
            <Stack >
                    <Heading textAlign="start" size="sm">Branding</Heading>
                    <Wrap direction="row" justify="space-between">
                        <WrapItem>
                            <Box maxW="700px">
                    <AutoUI
                        value={appValue?.branding}
                        onValueChange={val=>updateAppValue((appValue)=>{appValue.branding = val})}
                        schema={ {
                            "title": "Branding",
                            "type": "object",
                            "properties": {
                                "primary_color": {
                                    "title": "Primary color",
                                    "type": "string"
                                    },
                                "secondary_color": {
                                    "title": "Secondary color",
                                    "type": "string"
                                    },
                                "icon_type": {
                                    "title": "Icon type",
                                    "description": "Icon of the Chat button",
                                   
                                    "one_of":["default","geniously","chatbot"],
                                    "type": "string"
                                    },
                                "welcome_message": {
                                    "title": "Welcome message",
                                    "type": "string",
                                    "maxLength": 512,
                                    }
                            }
                        }}
                        excludeFields={["header_img_data"]}
                        customEditors={{
                            primary_color:<ColorPicker color={appValue?.branding?.primary_color} onColorChange={(val)=>updateAppValue((appValue)=>{appValue.branding = {...(appValue.branding  ||{}),primary_color:val}})}/>,
                            secondary_color:<ColorPicker color={appValue?.branding?.secondary_color} onColorChange={(val)=>updateAppValue((appValue)=>{appValue.branding = {...(appValue.branding  ||{}),secondary_color:val}})}/>
                        }}    
                    />
                    </Box>
                    </WrapItem>
                                        <WrapItem>
                                            <Flex direction="column" flexGrow={1} height="100%" >
                                                
                                                {/* <GeniouslyThemeProvider theme={{ colors: { "primary": appValue.branding?.primary_color, "secondary": appValue.branding?.secondary_color } }}> */}
                                                    <Flex borderWidth={1} flexGrow={1} borderColor="blackAlpha.400" rounded={10} overflow="hidden" shadow="lg" id="g-test" maxW="400px" bgColor="white">

                                                        <ChatContainer
                                                            onSend={() => { }}
                                                            messages={[{ type: "chat-response", message: appValue?.branding?.welcome_message }, { type: "chat-request", message: "Hi, can you help me?" }]} />
                                                    </Flex>

                                                    <Flex justify="end" align="end" >

                                                        <Box p="30px 0px 40px" css="& * {cursor: not-allowed !important}">

                                                            <ChatFloatingButton iconType={appValue?.branding?.icon_type} position="relative" bottom="10px"/>
                                                        </Box>
                                                    </Flex>
                                                {/* </GeniouslyThemeProvider> */}
                                            </Flex>
                                        </WrapItem>
                    </Wrap>
          
                </Stack>
            </TabPanel>
            <TabPanel  overflow="auto" h="calc(100vh - 205px)">
                <Box height="100%" cursor="initial">
                {appId && showChat && 
                    renderChat(!isChanged)
                }
                </Box>
            </TabPanel>
         </TabPanels>
        </Tabs>
        </Box>

       
            
          
        </Stack>


        
        
        </Stack>
    )
}


