import { Box } from "@chakra-ui/react";
import { ImSearch } from "react-icons/im";

interface IconProps {
    plain?: boolean;
    icon: any;
    color:string
    size?: "small" | "medium" | "large" | "xlarge";
    [key:string]:any
}

export const IconBox = ({plain, size, icon, color,...rest}:IconProps) => {

    function getSize(size, ratio=1){
        if (size === "small") return 15*ratio+"px"
        else if (size === "medium") return 25*ratio+"px"
        else if (size === "large") return 35*ratio+"px"
        else if (size === "xlarge") return 50*ratio+"px"
        else return  25*ratio+"px"
    }

 return(
    
     <Box alignSelf="start" justifySelf="start" fontSize={getSize(size)} color="white" bg={color||"teal.400"} padding={getSize(size,0.3)} borderRadius={getSize(size,0.2)}>
        {icon}
    </Box>
    
      )  
}
