
import { Box, Button, CloseButton, Flex, Icon, IconButton, Popover, PopoverArrow, PopoverBody, PopoverContent, PopoverTrigger, Stack, Text, useDisclosure } from "@chakra-ui/react";

import { type } from "os";
import { DataSource } from "../../models/dataModel";
import { BiPlusMedical } from "react-icons/bi";
import { MdClose } from "react-icons/md";
import { useState } from "react";
import { IconTrashX } from "@tabler/icons-react";



export function DeleteWithConfirm({
    label,
    icon,
    onClick,
    onCancel
}:{
    label?:boolean|string
    icon?:any
  onClick:(event?:any)=>any ,
  onCancel?:(event?:any)=>any

}) {

  const { isOpen, onToggle, onClose } = useDisclosure()
  function _onClick(e){
    e.stopPropagation()
    onToggle()
  }
  return (
    <Popover 
      isOpen={isOpen}
      onClose={onClose}
    >
  <PopoverTrigger >
  {label ?<Button onClick={_onClick}  size="xs" variant="ghost" colorScheme="blackAlpha" leftIcon={icon||<IconTrashX color="lightgray"  size="20px"/>} >{label} </Button>
    :<IconButton onClick={_onClick}  variant="ghost" colorScheme="blackAlpha" size="xs" aria-label="Delete" icon={icon||<IconTrashX  size="20px"/>}/>}
  </PopoverTrigger>
  <PopoverContent width="auto">
    <PopoverArrow p="5px"  />
    
    
    <PopoverBody>
      <Text fontSize="10pt" textAlign="center">

      Are you sure you want to <br/><b>delete</b> this item?
      </Text>
      <Stack p="5px">

    <Button size="xs" onClick={(e)=>{
      
      onClose()
      onCancel && onCancel(e)
    }} variant={"outline"}>Cancel</Button>
    <Button size="xs" onClick={onClick} colorScheme="red">Yes, Delete</Button>
    </Stack>
    </PopoverBody>
  </PopoverContent>
</Popover>

    
  );
}
