import { useState } from "react";
import { Button, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from "@chakra-ui/react";
import { ConfirmModal } from "./confirmModal";

export function EditModal<T>({ caption, value, onOk, onCancel, children }: {
    caption: string|JSX.Element;
    value: T;
    onOk: (value: T) => void;
    onCancel: () => void;
    children: (value: T, onChange: (newVal: T) => any) => JSX.Element;
}) {
    const [editedValue, setEditedValue] = useState(value);
    const [confirmModal, setConfirmModal] = useState<any>();

    

    function testModified() {
        return JSON.stringify(editedValue) != JSON.stringify(value);
    }
    return (<Modal 
        autoFocus={false}
        size="5xl"
        isOpen={true} onClose={() => {
        if (testModified())
            setConfirmModal(<ConfirmModal
              caption="Confirm" 
              question="You have unsaved changes. Do you want to discard them?" 
              colorScheme="orange"
              okButtonLabel="Discard"
              onOk={() => onCancel()}
              onCancel={()=>setConfirmModal(null)}
              />
              );
                

        else
            onCancel();
    }}>
        <ModalOverlay />
        <ModalContent width="80vw" >
            {confirmModal}
            <ModalHeader>{caption}</ModalHeader>
            <ModalBody>
                {children(editedValue, setEditedValue)}
            </ModalBody>
            <ModalFooter>
            <Button size={"sm"}  mr={2} onClick={onCancel}>Cancel</Button>
                <Button
                    size={"sm"}
                    colorScheme='brand'
                    onClick={() => onOk(editedValue)}
                >
                    OK
                </Button>
                
            </ModalFooter>
        </ModalContent>
    </Modal>);
}
