import { Box, Flex, HStack, Stack, Text } from "@chakra-ui/react";
import { SkillIcon } from "./skillIcons";
import { type } from "os";
import { Skill } from "../../models/dataModel";
import { BiPlusMedical } from "react-icons/bi";
import { IconLock } from "@tabler/icons-react";
import { DeleteWithConfirm } from "../DeleteWithConfirm/deleteWIthConfirm";



export function SkillBox({
    skill,
    size="medium",
    onClick,
    hoverEffects=true,
    onDelete
}:{
skill?:Skill
size?:"small"|"medium"|"large"
hoverEffects?:boolean
onClick?:()=>any,
onDelete?:()=>any
}) {
  
  return (
    <HStack className="skill-box" direction="row"  align="center" 
    cursor={onClick?"pointer":undefined }
    _hover={onClick&& hoverEffects?{border:"1px solid lightgray"}:undefined}
    border="1px solid transparent"  
    borderRadius="5px" p="5px"
    >
    <Stack 
        direction="row" 
        flexGrow={1}
        overflow="hidden"
        onClick={()=>{
            onClick && onClick()}
        } 
       

     >
        <SkillIcon size={size} type={skill?.type} />
        
        <Flex  alignSelf="center" align="start" direction="column" maxW="100%">
            <Text margin="0"  lineHeight="1em" fontSize={size} >{skill?.name||`New ${skill.type} skill`}</Text>
            {skill?.description && <Text isTruncated textOverflow="ellipsis" opacity="0.6"  margin="0" lineHeight="1em" fontSize="xs"  >{skill?.description}</Text>}
        </Flex>
    </Stack>
    {onDelete && <DeleteWithConfirm  onClick={onDelete}/>}
    </HStack>

  );
}

export function AddSkillButton({
    size="medium",
    text,
    type,
    description,
    locked,
    onClick,
    
}:{
    text?:string|JSX.Element,
    type?:string,
    locked?:boolean
    description?:string,
    size?:"small"|"medium"|"large"
    onClick?:()=>any,
    
}) {

    function getSize(size, ratio=1){
        if (size === "small") return 15*ratio+"px"
        else if (size === "medium") return 25*ratio+"px"
        else if (size === "large") return 35*ratio+"px"
        else if (size === "xlarge") return 50*ratio+"px"
        else return  25*ratio+"px"
    }

  
  return (
    <Flex className="skill-box" direction="row" opacity="0.6" align="center" _hover={{opacity:"0.9"}}cursor="pointer" p="0px 5px">
        <Stack direction="row" align="center" flexGrow="1 0 auto "  borderRadius={5}  onClick={onClick} overflow="hidden">
            <SkillIcon size={size} type={type}/>

            
            <Box flexShrink={0}>

            {!locked?(
                <BiPlusMedical color="gray" size={getSize(size,0.8)}/>
                
                ):(
                    <IconLock/>
                    )}  
            </Box>

        <Flex align="start" direction="column" justify="center" width="100%" overflow="hidden">
        {typeof(text)==="string"? <Text width="100%" textOverflow="ellipsis" overflow="hidden"  margin="0" lineHeight="1em" fontWeight={500} fontSize={size=="small"?"xs":size} color="black">{text}</Text> :text}
        {typeof(description)==="string"?   <Text isTruncated  margin="0"  lineHeight="1em" fontSize="xs"  color="gray">{description}</Text>:description}
        </Flex>
    </Stack>
    
    </Flex>
  );
}