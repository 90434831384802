import { Box, Flex, HStack, Heading, Spinner, Stack, Text } from "@chakra-ui/react";
import { TopicInfo } from "../../models/dataModel";
import { TopicBox } from "./topicBox";
import { getApi } from "../../apiService";
import useSWR from 'swr'
import { useParams } from "react-router-dom";
import { IconBulbOff } from "@tabler/icons-react";

  

const TopicInsightsBox = ({dateRange}:{
    dateRange?:{start:Date, end:Date}
}) => {
  const {appId}=useParams()
  const loadTopics = ()=>{
      return getApi().getTopics({appId,skip:0,limit:50})
  }
  const {data:topics, isLoading} = useSWR(`topics`,()=>loadTopics())

    return (
    <Box
    border="1px solid lightgray" 
    
    shadow="2xl" rounded={10} 
    overflow="hidden" 
    height="100%" 
    flexGrow={1}
    maxW="500px"
    >
    <Stack background="white.900" 
    bgGradient="linear(to-b, white, gray.50)"
    align="start"
    justify="start"
    spacing="0px"
    maxH="calc(100vh - 550px)"
    overflow="auto"

    
    >
        <Box bg="white" position="sticky" top={0} width="100%"   p="15px" textAlign="start" >

            <Heading as="h2" size="sm">Topics</Heading>
        </Box>
        <Stack   p="15px" align="center" alignSelf="center">
            {isLoading?(
              <Box p="90px">
                
                <Spinner size="xl"/>
              </Box>
            ):(
               !topics?.length? (
                <HStack p="40px" color="gray" align="start">
                  <Box flexShrink={0}>

                  <IconBulbOff size="50px" />
                  </Box>
                  <Text textAlign="start">It seems that there have not been enough conversations to gather meaningful insights yet</Text> 
                  </HStack>
               ):
                (topics?.map((topic,i)=><TopicBox key={i} topic={topic}/>))
              )}
        </Stack>
       
        </Stack>
        </Box>)

}

export {TopicInsightsBox}


// const fakeData=[
//     {
//       "topic_id": "1",
//       "topic_name": "Shipping issues: Delayed delivery",
//       "sentiment": { "positive": 0.2, "negative": 0.6, "neutral": 0.2 },
//       "count_total": 78,
//       "count_by_date": {
//         "2023-08-01": 15,
//         "2023-08-02": 23,
//         "2023-08-03": 40
//       }
//     },
//     {
//       "topic_id": "2",
//       "topic_name": "Product inquiries: OralB electric toothbrush",
//       "sentiment": { "positive": 0.7, "negative": 0.1, "neutral": 0.2 },
//       "count_total": 104,
//       "count_by_date": {
//         "2023-08-01": 30,
//         "2023-08-02": 34,
//         "2023-08-03": 40
//       }
//     },
//     {
//       "topic_id": "3",
//       "topic_name": "Refund requests: Damaged product",
//       "sentiment": { "positive": 0.1, "negative": 0.8, "neutral": 0.1 },
//       "count_total": 63,
//       "count_by_date": {
//         "2023-08-01": 18,
//         "2023-08-02": 20,
//         "2023-08-03": 25
//       }
//     },
//     {
//       "topic_id": "4",
//       "topic_name": "Order status: Tracking updates",
//       "sentiment": { "positive": 0.6, "negative": 0.2, "neutral": 0.2 },
//       "count_total": 92,
//       "count_by_date": {
//         "2023-08-01": 25,
//         "2023-08-02": 35,
//         "2023-08-03": 32
//       }
//     },
//     {
//       "topic_id": "5",
//       "topic_name": "Promotions: OralB toothpaste sale",
//       "sentiment": { "positive": 0.9, "negative": 0.05, "neutral": 0.05 },
//       "count_total": 71,
//       "count_by_date": {
//         "2023-08-01": 22,
//         "2023-08-02": 28,
//         "2023-08-03": 21
//       }
//     },
//     {
//       "topic_id": "6",
//       "topic_name": "Payment issues: Failed transaction",
//       "sentiment": { "positive": 0.1, "negative": 0.7, "neutral": 0.2 },
//       "count_total": 49,
//       "count_by_date": {
//         "2023-08-01": 15,
//         "2023-08-02": 18,
//         "2023-08-03": 16
//       }
//     },
//     {
//       "topic_id": "7",
//       "topic_name": "Account management: Password reset",
//       "sentiment": { "positive": 0.8, "negative": 0.1, "neutral": 0.1 },
//       "count_total": 38,
//       "count_by_date": {
//         "2023-08-01": 12,
//         "2023-08-02": 15,
//         "2023-08-03": 11
//       }
//     },
//     {
//       "topic_id": "8",
//       "topic_name": "Returns process: Exchange request",
//       "sentiment": { "positive": 0.3, "negative": 0.4, "neutral": 0.3 },
//       "count_total": 56,
//       "count_by_date": {
//         "2023-08-01": 16,
//         "2023-08-02": 22,
//         "2023-08-03": 18
//       }
//     },
//     {
//       "topic_id": "9",
//       "topic_name": "Product reviews: OralB floss",
//       "sentiment": { "positive": 0.6, "negative": 0.1, "neutral": 0.3 },
//       "count_total": 82,
//       "count_by_date": {
//         "2023-08-01": 25,
//         "2023-08-02": 28,
//         "2023-08-03": 29
//       }
//     },
//     {
//       "topic_id": "10",
//       "topic_name": "Technical support: Bluetooth connectivity",
//       "sentiment": { "positive": 0.4, "negative": 0.4, "neutral": 0.2 },
//       "count_total": 64,
//       "count_by_date": {
//         "2023-08-01": 18,
//         "2023-08-02": 22,
//         "2023-08-03": 24
//       }
//     }
//   ]