// import { } from "react";

import { Routes, Route } from "react-router-dom";



import   LoginPage from "./pages/loginPage";


import {AppSidebar, AppSidebarButton} from "./components/AppSidebar/appSidebar";
import HomePage from  "./pages/homePage";


import { memo, useEffect, useLayoutEffect, useState } from "react";
import { useAppContext } from "./appContext";
import Test from "./pages/testPage";
import NewAppPage from "./pages/newAppPage";
import AppsListPage from "./pages/appsListPage";
import AppEditPage from "./pages/appEditPage";
import AppHeader from "./components/AppHeader/appHeader";
import { Box, Flex, Grid, GridItem, Text } from "@chakra-ui/react";
import InsightsPage from "./pages/insightsPage";
import IntegrationsPage from "./pages/integrationsPage";
import ConversationsPage from "./pages/conversationsPage";
import PasswordResetPage from "./pages/passwordResetPage";
import AccountSettings from "./pages/accountSettingsPage";
import { IconDiscountCheck, IconUserCog,IconCreditCard, IconUsers, IconLayout2 } from "@tabler/icons-react";
import ShowCasePage from "./pages/showCasePage";
import IntegrationRedirectPage from "./pages/integrationRedirect";

const WithSidebar =({page, children, customMenuItems=undefined}:any)=>{
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  //const windowWidth=500
   const [menuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    // Add event listener to update window width when resized
    window.addEventListener('resize', handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const mobile = windowWidth<1000
  return (

    <Box height="100vh" >
             <AppHeader showMenuBtn={mobile} onMenuBtnClick={() => setMenuOpen(!menuOpen)} /> 

        
      <Grid
        templateAreas={!mobile ? `
                    "nav main"
                    `: `
                   
                    "main main"
                    `
        }
        gridTemplateRows={'calc(100vh - 50px)'}
        gridTemplateColumns={'190px 1fr'}
        height="100%"
        gap='0'
        color='blackAlpha.700'
        fontWeight='bold'
      >
       
        {!mobile && <GridItem bg='white' area={'nav'}>
          <AppSidebar>
            {customMenuItems}
          </AppSidebar>
        </GridItem>}
        <GridItem bg='gray.50' area={'main'} overflow="auto" >
          <Flex  height="100%">
          {mobile && menuOpen &&
          <Box position="absolute" top="0px" left={0} bgColor="white" zIndex={9} shadow="2xl" p="50px 0px 0px" overflow="hidden" height="100%">

            <AppSidebar>
            {customMenuItems}
          </AppSidebar>
          </Box>
           }
          {children || page}
          </Flex>
        </GridItem>

      </Grid>
    </Box>
    )
    
}
// , (prevProps, nextProps)=>{

//   return prevProps.page===nextProps.page

// })


const PageRouter = () => {

  const {currentUser} = useAppContext()

  
  return (
    
    

        
      <Routes>
        <Route path="/test"          element={<Test/>}/>
        {/* <Route path="app/new"          element={<NewAppPage/>}/> */}
        <Route path="/home"         element={<WithSidebar page={<AppsListPage/>}/>}/>
        
        <Route path="app/:appId/setup"       element={<WithSidebar page={<AppEditPage/>}/>}/>
        <Route path="/login"                element={<LoginPage mode="login"/>}/>
        <Route path="/signup"                element={<LoginPage mode="signup"/>}/>
        <Route path="/password-reset"       element={<PasswordResetPage/>}/>

        <Route path="/settings/:settingsSection"       element={<WithSidebar 
            page={<AccountSettings/>} 
            customMenuItems={
              <>
              <AppSidebarButton icon={<IconLayout2/>} name="Home" path="/home"/>
              <Text p="10px 20px 0px" fontWeight={500} borderRight="1px solid rgba(0,0,0,0.15)" fontSize="sm" color="gray.400">Settings</Text>
              <AppSidebarButton icon={<IconUserCog/>} name="My account" path="/settings/my-account"/>
              
              <AppSidebarButton icon={<IconCreditCard/>} name="Billing" path="/settings/billing"/>
              <AppSidebarButton icon={<IconUsers/>} name="My team" path="/settings/team"/>
              
        
              </>
            }
            />}/>
        {/* <Route path="/"                     element={<HomePage/>}/>
        
        <Route path="/home"                 element={<WithSidebar page={<DashboardPage/>}/>}/>
        <Route path="/signup"               element={<LoginPage mode="signup"/>}/>
      */}

      {/* <Route path="app/:appId"       element={<WithSidebar page={<AppEditPage/>}/>}/>
       */}

       <Route path="app/:appId/conversations"       element={<WithSidebar page={<ConversationsPage/>}/>}/>
       <Route path="app/:appId/insights"       element={<WithSidebar page={<InsightsPage/>}/>}/>
       <Route path="app/:appId/integrations"       element={<WithSidebar page={<IntegrationsPage/>}/>}/>
       <Route path="app/:appId/showcase"       element={<ShowCasePage/>}/>
       {currentUser?(
          <Route path="/"                element={<WithSidebar page={<AppsListPage/>}/>}/>
         )
        :(
          <Route path="/"                  element={<LoginPage mode="login"/>}/>
          )
       }
       <Route path="/integrations/:connector/connect/callback" element={<IntegrationRedirectPage/>}/>
        <Route path="*"               element={<WithSidebar page={<AppsListPage/>}/>}/>
 
          

       
        
          

      </Routes>
         
    
    
  );
};

export default PageRouter;
