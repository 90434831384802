

import { useEffect, useState } from "react";



import { getApi } from "../apiService";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import useSWR from 'swr'

import { useAppContext } from "../appContext";
import { Box, Button, Card, CardBody, CardFooter, CardHeader, ChakraProvider, Divider, Flex, HStack, Heading, IconButton, Image, SimpleGrid, Spinner, Switch, Text, VStack, Wrap, WrapItem } from "@chakra-ui/react";


import { IconEyeglass, IconLayoutSidebarLeftCollapse, IconPlugConnected, IconWebhook } from "@tabler/icons-react";

import { ConversationSessions } from "../components/Conversations/conversationSessions";
import {
    Popover,
    PopoverTrigger,
    PopoverContent
} from '@chakra-ui/react'
import { IconLayoutSidebarLeftExpand } from "@tabler/icons-react";

import {ChatContainer,GeniouslyThemeProvider } from "geniously-chat-ui"

export  default function ConversationsPage(){
    const {appId}=useParams()
    

    const navigate = useNavigate()
    const [searchParams, setSearchParams] = useSearchParams()



    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [selectedSession, setSelectedSession] = useState(undefined);
    useEffect(() => {
        const handleResize = () => {
          setWindowWidth(window.innerWidth);
        };
    
        // Add event listener to update window width when resized
        window.addEventListener('resize', handleResize);
    
        // Clean up the event listener when the component unmounts
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []);

        
        const mobile = windowWidth<1000

    
    
    const [conversationSessions, setConversationsSessions] = useState([1])
    const {data:messages, isLoading} = useSWR([selectedSession?.id],([session_id])=>{
        if (!session_id) return
        return getApi().getMessages(appId,session_id)
    })

    return ( <Flex direction="column"  height="100%" justify="stretch">
       
           <Flex  height="100%" direction="column" >
                    <HStack p="15px 0px 0px 15px">
             {(mobile)&&(
                <Popover
                gutter={0}
                >
                {({ isOpen, onClose }) => (
                    <>
                            <PopoverTrigger>
                                    <IconButton variant="ghost" icon={isOpen?(<IconLayoutSidebarLeftCollapse/>):<IconLayoutSidebarLeftExpand/>} aria-label="Menu" />
                            </PopoverTrigger>
                            <PopoverContent width="100%" p="0px">
                            <ConversationSessions onSessionClicked={(session)=>setSelectedSession(session)}/>
                            </PopoverContent>
                            </>
                )}
                </Popover>
                )}
                <Heading as="h2" size="sm">Conversation sessions</Heading>
            </HStack>
            
         

            <HStack justify="stretch" align="stretch" spacing={3} p="10px" flexGrow={1} >
            {!(mobile && messages)?(
                    
                        <ConversationSessions onSessionClicked={(session)=>setSelectedSession(session)} activeSessionId={selectedSession?.id}/>
                        
                    
                ):(
                <></> 
            )}
                <Flex rounded={10} borderWidth={1} bgColor="white" flexGrow={2}
                flexBasis="70%" 
                align="stretch" justify="center" direction="column" overflow="auto"
                >
                {isLoading?(
                        <Box p="40px" alignSelf="center">
                            <Spinner size="xl"/>
                        </Box>
                    ):(
                    messages ?(
                        
                        <GeniouslyThemeProvider theme={{colors:{primary:"#ff00c8", secondary:"black"}}}>
                        <Flex align="stretch" justify="stretch" flexGrow={1} bgColor="blue">

                        <ChatContainer messages={messages}  justify="start" bgColor="white"/>
                        </Flex>
                        </GeniouslyThemeProvider>
                        
                        
                    ):(

                        <HStack color="gray.400" justify="center">
                            <IconEyeglass size="30px"/>
                            <Text>{!conversationSessions?"No conversations yet":"Pick a session first"}</Text>
                        </HStack>
                    ) 
                )}
                </Flex>
            </HStack>
            </Flex>
        

    </Flex>
    
    )
}