

import { useEffect, useState } from "react";



import { getApi } from "../apiService";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import useSWR from 'swr'

import { useAppContext } from "../appContext";
import { Box, Card, CardBody, CardFooter, CardHeader, Flex, Heading, SimpleGrid, Spinner, Text, Wrap, WrapItem } from "@chakra-ui/react";

import { TopicInsightsBox } from "../components/Insights/topicInsights";


export  default function InsightsPage(){
    
    

    const navigate = useNavigate()
    const [searchParams, setSearchParams] = useSearchParams()

   
    const loadData = ()=>{
        
        
        // return getApi().getAllApps().then((res)=>{
        //     return res
        // })

    }

   

    const {data:allAps, isLoading} = useSWR(`apps`,()=>loadData())


    


    return ( <Flex direction="column" align="stretch" flexGrow={1} justify="stretch" >
        {isLoading?(
            <Box p="40px" alignSelf="center">
                <Spinner size="xl"/>
            </Box>
        ):(

        
            <Box p="20px" alignSelf="stretch" flexGrow={1} >
                <TopicInsightsBox/>

            </Box>
            
        )}
    </Flex>
    
    )
}