import { Box } from "@chakra-ui/react";
import { AiOutlineFileSearch } from "react-icons/ai";
import { ImSearch } from "react-icons/im";
import { IconBox } from "../IconBox/iconBox";
import { BiSupport, BiWrench } from "react-icons/bi";
import { HiBolt } from "react-icons/hi2";
import { FaRegCompass } from "react-icons/fa";
import { IconRoute } from "@tabler/icons-react";

interface SkillIconProps {
    plain?: boolean;
    type: string;
    size?: "small" | "medium" | "large" | "xlarge";
    [key:string]:any
}

export function getSkillColor(type:string){
    if (type === "search") return "teal.400"
    else if (type === "command") return "pink.400"
    else if (type === "navigate_on_page") return "cyan.400"
    else if (type === "support_agent") return "orange.400"
    else return "purple.400"
}

export const SkillIcon = (props:SkillIconProps) => {
    

    
    function getIcon(type:string){
        if (type === "search") return <AiOutlineFileSearch/>
        else if (type === "command") return <HiBolt/>
        else if (type === "navigate_on_page") return <FaRegCompass/>
        else if (type === "support_agent") return <BiSupport/>
        else if (type === "flow") return <IconRoute/>
        else return <BiWrench/>
    }
    return <IconBox  color={getSkillColor(props.type)} icon={getIcon(props.type)} {...props}/>
}