import { Box, Flex, HStack, Stack, Text } from "@chakra-ui/react"
import { IconApi, IconDeviceDesktopCode, IconLock, IconPlugConnected } from "@tabler/icons-react"
import { IconBox } from "../IconBox/iconBox"
import { DeleteWithConfirm } from "../DeleteWithConfirm/deleteWIthConfirm"
import { BiPlusMedical } from "react-icons/bi"

export function CommandTypeBox({
    size="medium",
    text,
    add,
    type,
    description,
    locked,
    active,
    onClick,
    onDelete
}:{
    text?:string|JSX.Element,
    type?:string,
    add?:boolean
    active?:boolean
    locked?:boolean
    description?:string,
    size?:"small"|"medium"|"large"
    onClick?:()=>any,
    onDelete?:()=>any
}) {

    function getSize(size, ratio=1){
        if (size === "small") return 15*ratio+"px"
        else if (size === "medium") return 25*ratio+"px"
        else if (size === "large") return 35*ratio+"px"
        else if (size === "xlarge") return 50*ratio+"px"
        else return  25*ratio+"px"
    }

  
  return (
    <Stack className="skill-box" direction="row" opacity={active==false?"0.6":undefined} align="center" _hover={{opacity:"0.9"}} 
    justify="space-between" 
    onClick={!locked?onClick:undefined} cursor="pointer" p="0px 5px">
        <HStack>
            
            {add&&(
                <BiPlusMedical color="gray" size={20}/>
             )}

            {locked?(  
                <IconBox color="gray.400" icon={<IconLock color="white" />}></IconBox>
            ):(
                <CommandTypeIcons size={size} type={type}/>
            )}  
            

        <Flex align="start" direction="column" justify="center">
            <Text margin="0" lineHeight="1em" fontWeight={500} fontSize={size=="small"?"xs":size} color="black">{text}</Text> 
            <Text fontSize={"xs"} isTruncated  margin="0" lineHeight="1em">{description}</Text>
        </Flex>
        </HStack>
        {onDelete && <DeleteWithConfirm  onClick={onDelete}/>}
    </Stack>
  );
}



const CommandTypeIcons = (props:{
    plain?: boolean;
    type: string;
    size?: "small" | "medium" | "large" | "xlarge";
    [key:string]:any
}) => {
    function getSkillColor(type:string){
        if (type === "?") return "cyan.500"

        else return "pink.400"
    }

    
    function getIcon(type:string){

        if (type === "rest_api") return <IconApi/>
        else if (type === "client_action") return <IconDeviceDesktopCode/>
        
        else return <IconPlugConnected/>
    }
    return <IconBox  color={getSkillColor(props.type)} icon={getIcon(props.type)} {...props}/>
}