import { Box, Button, Popover, PopoverArrow, PopoverBody, PopoverCloseButton, PopoverContent, PopoverHeader, PopoverTrigger, Stack, Text, useDisclosure } from "@chakra-ui/react"

import { useState } from "react"
import { ChromePicker  } from 'react-color'

const ColorPicker= ({color, onColorChange}:{
    color:string,
    onColorChange:(color:string)=>void,
}) => {

const [colorState, setColorState] = useState<any>(color)
const { isOpen, onToggle, onClose } = useDisclosure()
return (<Popover
  isOpen={isOpen}
  onClose={()=>{
    onColorChange(colorState)
    
      onClose()
    }}
>
  <PopoverTrigger

  >
    <Button   onClick={onToggle} variant='solid' size="xs"  backgroundColor={colorState} border="1px white solid" boxShadow="outline">
      {/* <Stack spacing={0}  >
        <Text lineHeight={1} fontSize="10px"  mixBlendMode="revert" >Click to change</Text>
        <Text lineHeight={1} fontSize="12px"  mixBlendMode="hard-light" >{colorState}</Text>

      </Stack> */}
        
      </Button>
  </PopoverTrigger>
  <PopoverContent width="auto" background="white" p={0} boxShadow="lg">
    <PopoverArrow />
    
        <ChromePicker color={colorState} 
        
        onChange={e=>{
          setColorState(e.hex)
          }}
          onColorChange={onColorChange}
          />
          

          <Button size="xs" onClick={()=>{
            setColorState(color)
            onClose()
          }
            }>Cancel</Button>
          
    {/* <PopoverBody>
        </PopoverBody> */}
  </PopoverContent>
</Popover>)
}

export default ColorPicker