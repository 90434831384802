import { Badge, Box, ButtonGroup, CloseButton, Flex, IconButton, Popover, PopoverArrow, PopoverBody, PopoverCloseButton, PopoverContent, PopoverHeader, PopoverTrigger, Progress, Stack, Text, useDisclosure, useToast } from "@chakra-ui/react"
import { IconBell, IconPlayerPlay } from "@tabler/icons-react"
import { forwardRef, memo, useCallback, useEffect, useRef, useState } from "react"
import { Spinner } from '@chakra-ui/react'
import { NotificationService } from "./notificationService"
import { useAppContext } from "../../appContext"
import useSWR from 'swr'
import NotificationBox from "./notificationBox"
import { onAuthStateChanged } from "firebase/auth"



const NotificationsList = ({notificationsService, running, statusUpdate}:{
    notificationsService:NotificationService,
    running:boolean,
    statusUpdate?:StatusUpdate
})=>{

const [notifications, setNotifications] = useState<StatusUpdate[]>([])
const {data:notificationsData, isLoading} = useSWR([`notifications`,notificationsService, running] ,()=>{
    if (notificationsService){ 
        if (running){
            return notificationsService.getRunning()
        }
        else{
            return notificationsService.getNotifications()
        }
    }
})



useEffect(()=>{
    setNotifications(notificationsData)
    if (!running &&notificationsData&& notificationsData.length){
        setTimeout(()=>{
            notificationsService.clearNotifications()
        },1500)
    }
},[notificationsData])

useEffect(()=>{
    if (notifications){
        let index = notifications.findIndex((notification)=>notification.process_id==statusUpdate.process_id)
        if (index>=0){
            let new_notifications = [...notifications]
            new_notifications[index]=statusUpdate
            setNotifications(new_notifications)

        }
    }
},[statusUpdate])

if (isLoading){
    return <Box><Spinner/></Box>
}
return <Stack spacing="0px">
    {notifications?.length?  notifications.map((notification, index)=>{
        return <NotificationBox key={index} notification={notification} />
    }
    )
    :(
        <Text>{running?"There are no running processes at the moment":"There are no new notifications"}</Text>
    )

}
</Stack>
}

export const NotificationsCenter=memo(()=>{

    //const [notificationsCount, setNotificationsCount] = useState<number>(990)
    //const [runningProcessesCount, setRunningProcessesCount] = useState<number>(102)

    
    
    const toast = useToast()
    const [notificationService, setNotificationService] = useState<NotificationService>(null)
    const {currentUser} = useAppContext()
    const toastIdRef = useRef<any>()
    const lastProcessId = useRef<any>()
    //const lastProcessRunning = useRef<any>()
    const [lastProcessRunning, setLastProcessRunning]=useState<any>()
    const [lastChange, setLastChange]=useState<StatusUpdate>()
    const { isOpen: isRunningOpen, onToggle: onRunningToggle, onClose: onRunningClose } = useDisclosure()
    

    const onNotificationCallback = useCallback((notification:StatusUpdate)=>{
        let toastData = {
            duration: 10000,
            render: ({onClose}) => (
                <NotificationBox 
                    notification={notification}  
                    onClose={onClose}/>
            ),
        }
        setLastChange(notification)
        if (toastIdRef.current && toast.isActive(toastIdRef.current)) {
            toast.update(toastIdRef.current, toastData)
        }
        else if (!isRunningOpen && lastProcessId.current!==`${notification.process_id}-${notification.is_running}`){
            toastIdRef.current = toast({
                position: 'top-right',
                isClosable: true,
                containerStyle: {
                    marginTop: '50px'
                },
                ...toastData
            })
        }
        lastProcessId.current = `${notification.process_id}-${notification.is_running}`
        setLastProcessRunning(lastProcessId.current)
        
    },[isRunningOpen])
    useEffect(() => {
        if (currentUser){

            let notification_service = NotificationService.getInstance({authToken:()=>currentUser.getAccessToken(), onNotification:onNotificationCallback})
            setNotificationService(notification_service)
            
        }
        
    },[currentUser])


    useEffect(()=>{
        if (isRunningOpen && toastIdRef.current){
            toast.close(toastIdRef.current)
        }
    },[isRunningOpen])

    const {data:status, isLoading} = useSWR([`status`,notificationService, lastProcessRunning] ,()=>{
        if (notificationService){ 
            return notificationService.getStatus()
        }
    })

    



    
    
    
    const runningProcessesCount =  status ? status.running_count : 0
    const notificationsCount =  status? status.notifications_count :0
    if(isLoading || !notificationService){
        return <Flex  justify="center" align="center"><Spinner color="lightgray" size="sm"/></Flex>
    }
    else
    return (
    <ButtonGroup variant="ghost" spacing='1'>
        
        {runningProcessesCount>0 &&(
           
            <Popover 
            isOpen={isRunningOpen}
            onClose={onRunningClose}
            >
            <PopoverTrigger>
                    <IconButton                    
                    aria-label={`${runningProcessesCount} running processes `}
                    onClick={()=>onRunningToggle()}
                    icon={
                        <Flex position="relative" align="center" justify="center"> 
                            <Spinner/>
                            <Box position="absolute"  cursor="pointer" >
                                {Math.min(runningProcessesCount,9)}
                                {runningProcessesCount>9 && "+"}
                            </Box>
                        </Flex>
                        }
                    />
                    
                </PopoverTrigger>
                <PopoverContent>
                    <PopoverArrow />
                    {/* <PopoverCloseButton /> */}
                    <PopoverHeader >
                        <Stack direction="row" align="center">
                            <IconPlayerPlay size="15px" color="gray" />
                            <Text>{`${runningProcessesCount} running processes `} </Text>
                        </Stack>
                    
                    </PopoverHeader>
                    <PopoverBody>
                    {isRunningOpen &&  <NotificationsList notificationsService={notificationService} running={true} statusUpdate={lastChange}/>}
                        

                    </PopoverBody>
                </PopoverContent>
            </Popover>
        )}
       

        <Popover  isLazy={true}>
            <PopoverTrigger>
                <Flex position="relative" align="end" justify="end"> 
                    <IconButton
                        aria-label='Notifications Center'
                        icon={<IconBell />}
                        />
                        {!!notificationsCount && <Box position="absolute" borderRadius="full" background="blue.400" p="2px 2px"  justifySelf="end" margin="5px" cursor="pointer">
                            <Text color="white" fontSize="xs" fontWeight="bold" lineHeight="1em">{Math.min(99,notificationsCount)} {notificationsCount>99 && "+"} </Text>
                        </Box>}
                </Flex>
            </PopoverTrigger>
            <PopoverContent>
                <PopoverArrow />
                {/* <PopoverCloseButton /> */}
                <PopoverHeader >
                    <Stack direction="row" align="center">
                        <IconBell size="15px" color="gray" />
                        <Text>Notifications </Text>
                    </Stack>
                
                </PopoverHeader>
                <PopoverBody>
                    <NotificationsList notificationsService={notificationService} running={false}/>

                </PopoverBody>

            </PopoverContent>
        </Popover>
    </ButtonGroup>)

})